import React, { Component } from 'react';
import { TextField,FormLabel,RadioGroup,Radio,FormControlLabel   } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/date-fns';

import { useForm } from 'react-hook-form';
import axios from 'axios';

export default function CenturyCapitalPartners() {
    const [occupancy, setOccupancy] = React.useState('owner');
    const [areThereAnyLiensOnProperty, setareThereAnyLiensOnProperty] = React.useState('no');
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [value, setValue] = React.useState('no');
    const { register, handleSubmit, errors,reset  } = useForm();
    const [succes, setSucces] = React.useState();

    const areThereAnyLiensOnPropertyChange = (event) => {
      setareThereAnyLiensOnProperty(event.target.value);
    };
    const occupancyChange = (event) => {
      setOccupancy(event.target.value);
    };
    const handleChange = (event) => {
        setValue(event.target.value);
      };

   const onSubmit = (data, e) => {
      let formData = new FormData()

      formData.set("broker_name", data.broker_name)
      formData.set("broker_email", data.broker_email)
      formData.set("principal_name", data.principal_name)
      formData.set("principal_email", data.principal_email)
      formData.set("name_borrowing_entity", data.name_borrowing_entity)
      formData.set("borrowers_office_number", data.borrowers_office_number)
      formData.set("borrowers_mobile_number", data.borrowers_mobile_number)
      formData.set("borrowers_mail", data.borrowers_mail)
      formData.set("borrowers_state", data.borrowers_state)
      formData.set("borrowers_zip", data.borrowers_zip)
      formData.set("invest_cash_on_hand", data.invest_cash_on_hand)
      formData.set("loan_request", data.loan_request)
      formData.set("purpose_loan", data.purpose_loan)
      formData.set("property_owned", data.property_owned)
      formData.set("borrower_closing_", data.borrower_closing_)
      formData.set("property_type", data.property_type)
      formData.set("operating_income", data.operating_income)
      formData.set("property_address", data.property_address)
      formData.set("collateral_value", data.collateral_value)
      formData.set("encumbrances_liens", data.encumbrances_liens)
      formData.set("original_price", data.original_price)
      formData.set("occupancy", data.occupancy)
      formData.set("all_loans_property", data.all_loans_property)
      formData.set("short_summary", data.short_summary)
      formData.set("how_find_us", data.how_find_us)
      formData.set("platform", 'Rich Grand Dad')


      axios.post('https://centurycapllc.com/api/index.php/wp-json/contact-form-7/v1/contact-forms/115/feedback', formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
       .then((response) => {
         e.target.reset();
         setSucces('Thank you for contacting us - we will get back to you soon!');
       }, (error) => {
         console.log(error);
       });
    };
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-12">
                   <p class="f-700 primary-c no-margin uppercase mar-t-20">Broker and Principal’s Details
</p>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Broker Name" name="broker_name" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.broker_name && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Broker Email" name="broker_email" inputRef={register({
                     required: "This field is required",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                     }
                  })}/>
                  <span className="error_field">{errors.broker_email && errors.broker_email.message}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Principal Name" name="principal_name" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.principal_name && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Principal Email" name="principal_email" inputRef={register({
                     required: "This field is required",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                     }
                  })}/>
                  <span className="error_field">{errors.principal_email && errors.principal_email.message}</span>
                 </div>
                 <div className="col-md-12">
                   <p class="f-700 primary-c no-margin uppercase mar-t-20">Borrower and Loan Details</p>
                 </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="Name of borrowing entity" name="name_borrowing_entity" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.name_borrowing_entity && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Office Phone" name="borrowers_office_number" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.borrowers_office_number && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Mobile Number" name="borrowers_mobile_number" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.borrowers_mobile_number && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="Mailing address" name="borrowers_mail" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.borrowers_mail && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="State" name="borrowers_state" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.borrowers_state && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Zip" name="borrowers_zip" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.borrowers_zip && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Actual cash on hand to invest" name="invest_cash_on_hand" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.invest_cash_on_hand && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Loan request" name="loan_request" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.loan_request && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Purpose of loan" name="purpose_loan" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.purpose_loan && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Other investment properties owned?" name="property_owned" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.property_owned && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="When is closing needed?" name="borrower_closing_" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.borrower_closing_ && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                   <p class="f-700 primary-c no-margin uppercase mar-t-20">Property Details</p>
                 </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="Property type*
(raw land, income producing, etc.)" name="property_type" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.property_type && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="Net operating income
(If income producing)" name="operating_income" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.operating_income && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="Property address" name="property_address" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.property_address && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="Collateral value" name="collateral_value" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.collateral_value && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="BKs, foreclosures, judgments, tax liens, violations or any types of encumbrances or liens*" name="encumbrances_liens" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.encumbrances_liens && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                    <div className="padd-t-15">
                    <FormControl>
                      <InputLabel id="occupancy-label">occupancy</InputLabel>
                      <Select
                        labelId="occupancy-label"
                        id="occupancy"
                        value={occupancy}
                        name="occupancy"
                        onChange={occupancyChange}
                      >
                        <MenuItem value={'owner'}>Owner</MenuItem>
                        <MenuItem value={'non-owner'}>Non-owner</MenuItem>
                      </Select>
                    </FormControl>
                    </div>
                  </div>
                  <div className="col-md-12">
                   <TextField id="standard-basic" label="Date purchased and original price" name="original_price" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.original_price && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                  <div className="padd-t-15">
                    <FormLabel component="legend">If this is a refinance, are there any liens/mortgages on the property?*</FormLabel>
                    <RadioGroup aria-label="are_there_any_liens_on_property" name="are_there_any_liens_on_property" value={areThereAnyLiensOnProperty} onChange={areThereAnyLiensOnPropertyChange}>
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                    </div>
                  </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="Short summary of your loan request, past experience and exit strategy to help us understand your objectives" name="short_summary" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.short_summary && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="How did you find us?" name="how_find_us" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.how_find_us && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                     <span className="succes_field">{succes}</span>
                     <button className="btn_blue mar-t-30"><i className="icon icon-rocket"></i>Send</button>
                 </div>
               </div>
         </form>
        )
}