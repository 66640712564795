import React, { Component } from 'react';
import { TextField,FormLabel,RadioGroup,Radio,FormControlLabel   } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/date-fns';

import { useForm } from 'react-hook-form';
import axios from 'axios';


export default function HardMoneyMobile() {
    const [occupancy, setOccupancy] = React.useState('owner');
    const [areThereAnyLiensOnProperty, setareThereAnyLiensOnProperty] = React.useState('no');
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const { register, handleSubmit, errors,reset  } = useForm();
    const [succes, setSucces] = React.useState();   

    const areThereAnyLiensOnPropertyChange = (event) => {
        setareThereAnyLiensOnProperty(event.target.value);
      };
    const occupancyChange = (event) => {
      setOccupancy(event.target.value);
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
      };

   const onSubmit = (data, e) => {
      let formData = new FormData()
      formData.set("broker_name", data.broker_name)
      formData.set("broker_email", data.broker_email)
      formData.set("principal_name", data.principal_name)
      formData.set("principal_email", data.principal_email)
      formData.set("phone_number", data.phone_number)
      formData.set("mobile_number", data.mobile_number)
      formData.set("phone_number", data.phone_number)
      formData.set("address", data.address)
      formData.set("country", data.country)
      formData.set("state", data.state)
      formData.set("zip", data.zip)
      formData.set("number_of_entity", data.number_of_entity)
      formData.set("property_type", data.property_type)
      formData.set("net_operating_income", data.net_operating_income)
      formData.set("property_address", data.property_address)
      formData.set("collateral_value", data.collateral_value)
      formData.set("loan_request", data.loan_request)
      formData.set("purpose_of_loan", data.purpose_of_loan)
      formData.set("bks_tax_liens", data.bks_tax_liens)
      formData.set("occupancy", occupancy)
      formData.set("date_purchased", selectedDate)
      formData.set("original_price", data.original_price)
      formData.set("actual_cash_in_hand", data.actual_cash_in_hand)
      formData.set("are_there_any_liens_on_property", areThereAnyLiensOnProperty)
      formData.set("how_much_owe", data.how_much_owe)
      formData.set("short_summary", data.short_summary)
      formData.set("other_investment_properties", data.other_investment_properties)
      formData.set("closing_needed", data.closing_needed)
      formData.set("how_find_us", data.how_find_us)
      formData.set("platform", 'Rich Grand Dad')

      axios.post('https://app.hardmoneymobile.com/api/webdeal', formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
       .then((response) => {
         e.target.reset();
         setSucces('Thank you for contacting us - we will get back to you soon!');
       }, (error) => {
         console.log(error);
       });
   };  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
        <div className="col-md-6">
           <TextField id="broker_name" label="Broker’s name" name="broker_name" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.broker_name && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="broker_email" label="Broker’s email"  name="broker_email" inputRef={register({
                     required: "This field is required",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                     }
                  })}/>
            <span className="error_field">{errors.broker_email && errors.broker_email.message}</span>
        </div>
        <div className="col-md-6">
           <TextField id="principal_name" label="Principal’s name"  name="principal_name" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.principal_name && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="principal_email" label="Principal’s email" name="principal_email" inputRef={register({
                     required: "This field is required",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                     }
                  })}/>
            <span className="error_field">{errors.principal_email && errors.principal_email.message}</span>
        </div>
        <div className="col-md-6">
           <TextField id="phone_number" label="Phone (office)"  name="phone_number" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.phone_number && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="mobile_number" label="Mobile numbers"  name="mobile_number" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.mobile_number && "This field is required"}</span>
        </div>
        <div className="col-md-12">
           <TextField id="address" label="Address" rows={6}
            multiline  name="address" inputRef={register({ required: "This is required" })}/>
            <span className="error_field">{errors.address && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="country" label="Country"  name="country" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.country && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="state" label="State"  name="state" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.state && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="zip" label="Zip"  name="zip" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.zip && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="number_of_entity" label="Name of borrowing entity"  name="number_of_entity" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.number_of_entity && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="property_type" label="Property type* (raw land, income producing etc.)" name="property_type" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.property_type && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="net_operating_income" label="Net operating income (if income producing)"  name="net_operating_income" type="number" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.net_operating_income && "This field is required"}</span>
        </div>
        <div className="col-md-12">
           <TextField id="property_address" label="Property address" rows={6}
multiline name="property_address" inputRef={register({ required: "This is required" })}/>
<span className="error_field">{errors.property_address && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="collateral_value" label="Collateral value (current as-is value)"  name="collateral_value" inputRef={register({ required: "This is required" })} type="number" />
           <span className="error_field">{errors.collateral_value && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="loan_request" label="Loan request" name="loan_request" inputRef={register({ required: "This is required" })} type="number"/>
<span className="error_field">{errors.loan_request && "This field is required"}</span>
        </div>
        <div className="col-md-12">
           <TextField id="purpose_of_loan" label="Purpose of loan (use of proceeds)" rows={6}
multiline name="purpose_of_loan" inputRef={register({ required: "This is required" })}/>
<span className="error_field">{errors.purpose_of_loan && "This field is required"}</span>
        </div>
        <div className="col-md-12">
           <TextField id="bks_tax_liens" label="BKs, foreclosures, judgments, tax liens, violations or any types of encumbrances or liens" rows={6}
multiline name="bks_tax_liens" inputRef={register({ required: "This is required" })}/>
<span className="error_field">{errors.bks_tax_liens && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <div className="padd-t-15">
          <FormControl>
            <InputLabel id="occupancy-label">occupancy</InputLabel>
            <Select
              labelId="occupancy-label"
              id="occupancy"
              value={occupancy}
              name="occupancy"
              onChange={occupancyChange}
            >
              <MenuItem value={'owner'}>Owner</MenuItem>
              <MenuItem value={'non-owner'}>Non-owner</MenuItem>
            </Select>
          </FormControl>
          </div>
        </div>
        <div className="col-md-6">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                margin="normal"
                id="date_purchased"
                label="Date purchased"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                name="date_purchased"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
        </div>
        <div className="col-md-6">
           <TextField id="original_price" label="Original price (if owned)"  name="original_price" inputRef={register({ required: "This is required" })} type="number"/>
           <span className="error_field">{errors.original_price && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="actual_cash_in_hand" label="Actual cash in hand"  name="actual_cash_in_hand" inputRef={register({ required: "This is required" })} type="number"/>
           <span className="error_field">{errors.actual_cash_in_hand && "This field is required"}</span>
        </div>
        <div className="col-md-12">
         <div className="padd-t-15">
          <FormLabel component="legend">If this is a refinance, are there any liens/mortgages on the property?</FormLabel>
          <RadioGroup aria-label="are_there_any_liens_on_property" name="are_there_any_liens_on_property" value={areThereAnyLiensOnProperty} onChange={areThereAnyLiensOnPropertyChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          </div>
        </div>
        <div className="col-md-12">
           <TextField id="how_much_owe" label="If yes, how much do you owe for all loans on the property?"  type="number"
 name="how_much_owe" inputRef={register({ })}/>
<span className="error_field">{errors.how_much_owe && "This field is required"}</span>
        </div>
        <div className="col-md-12">
           <TextField id="short_summary" label="Short summary of your loan request, past experience and exit strategy to help us understand your objectives." rows={6}
multiline name="short_summary" inputRef={register({ required: "This is required" })}/>
<span className="error_field">{errors.short_summary && "This field is required"}</span>
        </div>
        <div className="col-md-12">
           <TextField id="other_investment_properties" label="Other investment properties owned, please list with NOI and equity value including all liens" rows={6}
multiline name="other_investment_properties" inputRef={register({ required: "This is required" })}/>
<span className="error_field">{errors.other_investment_properties && "This field is required"}</span>
        </div>
        <div className="col-md-12">
           <TextField id="closing_needed" label="When is closing needed?" rows={6}
multiline name="closing_needed" inputRef={register({ required: "This is required" })}/>
<span className="error_field">{errors.closing_needed && "This field is required"}</span>
        </div>
        <div className="col-md-12">
           <TextField id="how_find_us" label="How did you find us?" rows={6}
multiline name="how_find_us" inputRef={register({ required: "This is required" })}/>
<span className="error_field">{errors.how_find_us && "This field is required"}</span>
        </div>
        <div className="col-md-12">
        <span className="succes_field">{succes}</span>
           <button className="btn_blue mar-t-30"><i className="icon icon-rocket"></i>Send</button>
        </div>
      </div>
      </form>
        )
}