import React from 'react';
import { Route, BrowserRouter as Router, Switch  } from 'react-router-dom';

import ScrollIntoView from "../components/ScrollIntoView";

import  Home  from '../pages/index';
import  FundingSolutions  from '../pages/funding_solutions';
import  FAQ  from '../pages/faq';
import  About  from '../pages/about';
import  Apply  from '../pages/apply';
import  ApplySingle  from '../pages/apply/index';
import  News  from '../pages/news';
import  NewsSingle  from '../pages/news/index';
import  Partner  from '../pages/partner';
import  Contact  from '../pages/contact';

export const AppRouter = () => {
  return (
    <Router>
      <ScrollIntoView>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/funding_solutions" component={FundingSolutions} />
          <Route path="/faq" component={FAQ} />
          <Route path="/about" component={About} />
          <Route path="/apply/:slug" component={ApplySingle}/>
          <Route path="/apply" component={Apply} />
          <Route path="/news/:slug" component={NewsSingle}/>
          <Route path="/news" component={News} />
          <Route path="/partner" component={Partner} />
          <Route path="/contact" component={Contact} />
        </Switch>
        </ScrollIntoView>
    </Router>
  );
}