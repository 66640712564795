import styles from '../styles/Home.module.css'
import Layout from '../components/layout'
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react'
import jQuery from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Helmet} from "react-helmet";
window.jQuery = jQuery;


export default function Home() {
  const editorRef = useRef()
  const [editorLoaded, setEditorLoaded] = useState(false)
  const [scrolled, setScrolled] = useState(0)
  const [scrolledY, setScrolledY] = useState(0)
  const { OwlCarousel } = editorRef.current || {}

  const handleScroll = () => {
    const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

      let elem = document.getElementById('footer');
      var rect = elem.getBoundingClientRect();
      var pl = rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight;

      const scrolled_ = winScroll
      let scrolled_y= scrolled_
      if(pl){
        console.log('ff')
        setScrolled(scrolled_ * 1.5)
        setScrolledY(scrolled_y)
      }
      
  }
  useEffect(() => {
    editorRef.current = {
      OwlCarousel: require('react-owl-carousel')
    }
    setEditorLoaded(true)
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });
  
  return (
    <Layout>
        <Helmet>
          <title>Rich Granddad | Like the one you always wished for...</title>
        </Helmet>
        <main className={styles.main}>
          <div className={styles.IndexBanner} style={{backgroundImage:"url('/banner/index.png')"}} data-aos='fade-in'>
              <div className="BannerContainWrapper">
                <div className="BannerContainSub">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 luxy-el" data-aos='fade-up'>
                        <h1 className="f-800 rustin-red mar-b-10">It’s easy to find<br /> funding and <br />working capital…</h1>
                        <h5 className="f-800 soft-red mar-b-10" >If you had a rich granddad. Well now you do!</h5>
                        <h6 className="f-500  mar-b-40" >Just let your rich granddad know what you need!</h6>
                        <Link to="/apply" className="btn btn-default">Apply Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="DrandDadFlower">
                <img src="/banner/banner-grand-dad-flower.png" alt="flower"/>
              </div>
              <div className="DrandDadVehicle" style={{transform: 'translate3d('+scrolled+'px,'+scrolledY+'px,0px)'}} id="DrandDadVehicle">
                <img src="/banner/banner-grand-dad-vehicle.png"  alt="vehicle"/>
              </div>
          </div>
          <section className="soft-blue-b padd110 track_p" style={{backgroundImage:"url('/images/step-sp.png')"}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                   <h6 className="f-700 white-c" data-aos="fade-right">Your rich granddad’s track record:</h6>
                   <ul className="track-view">
                     <li className="f-500 white-c" data-aos="fade-right" data-aos-delay="600"><h6>Over $500 million funded</h6></li>
                     <li className="f-500 white-c" data-aos="fade-right" data-aos-delay="1200"><h6>Over 10,000 deals funded</h6></li>
                   </ul>
                </div>
              </div> 
            </div> 
            <div className="index-p">
              <div className="container">
                <div className="row">
                  <div className="col-md-5 display-inline" data-aos="fade-right">
                    <span className="pio_"></span>
                    <h4 className="f-700 white-c line45">Fast Funding and <br/>Working Capital is Easy <br/>with Rich Granddad</h4>
                  </div><div className="col-md-7 display-inline" data-aos="fade-left">
                     <p className="f-500 white-c">Rich Granddad provides alternative funding and working capital in a fast and easy manner, for small businesses who need capital to start up or those who need a steady cash flow to maintain operation.</p>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="arrow_p" style={{backgroundImage:"url('/images/arrow.png')"}}></div>
          </section>
          <section className="logo_brand padd110">
            <div className="container">
              <div className="row" data-aos='fade-up'>
                <div className="col-md-12">
                    <h1 className="f-800 rustin-black mar-b-60">Rich Granddad’s Wallet</h1>
                </div>
                <div className="col-md-3 display-inline verticle-bottom">
                  <img src="/images/lend-on-land.png" />
                </div>
                <div className="col-md-3 display-inline verticle-bottom">
                  <img src="/images/hard-money.png" />
                </div>
                <div className="col-md-3 display-inline verticle-bottom">
                  <img src="/images/solid-oak.png" />
                </div>
                <div className="col-md-3 display-inline verticle-bottom">
                  <img src="/images/empire-factors.png" />
                </div>
                <div className="col-md-3 display-inline verticle-bottom">
                  <img src="/images/dan-advance.png" />
                </div>
                <div className="col-md-3 display-inline verticle-bottom">
                  <img src="/images/ccp.png" />
                </div>
                <div className="col-md-3 display-inline verticle-bottom">
                  <img src="/images/quantak.png" />
                </div>
              </div>
            </div>
          </section>
          <section className="section padd110 CoverBannerpl how-get white-blue-b" id="how-get" style={{backgroundImage:"url('/images/home-section-02.png')"}} data-aos='fade-up'>
             <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <h6 className="f-700 line35 arrow_">Get a loan from your <br/>rich granddad in <br/>3 easy steps! </h6>
                  </div>
                  <div className="col-md-3">
                    <div className="stepWrapper">
                      <img src="/images/step-01.png" />
                      <p className="f-700 mar-b-10">Find the type of funding you need</p>
                      <p className="small-title">Your rich granddad has different types of funding available: from bridge loans to cash advances. Choose the one that’s right for you.</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="stepWrapper">
                      <img src="/images/step-02.png" />
                      <p className="f-700 mar-b-10">Fill in our application</p>
                      <p className="small-title">Once you find the right type of funding, let your rich granddad know what you need by filling in our online application.</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="stepWrapper">
                      <img src="/images/step-03.png" />
                      <p className="f-700 mar-b-10">Get funded</p>
                      <p className="small-title">Once you have submitted your application, your rich granddad will review your file and grant a pre-approval. Funds could be available within 3 days after the final approval.</p>
                    </div>
                  </div>
                </div>
             </div>
          </section>
          <section className="section padd110 help" id="help"  data-aos='fade-up'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="f-800 rustin-black mar-b-10">How We Help Businesses</h1>
                            </div>
                        </div>
                    </div>
                    <div className="helpItem mar-t-40">
                      <div className="container">
                         <div className="row">
                            <div className="col-md-4">
                                <div className="item">
                                    <h5 className="f-700 mar-b-30">Obtain capital</h5>
                                    <br />
                                    <img src="/images/help/boss.png" />
                                    <p className="mar-t-40">We help small business owners and entrepreneurs obtain the capital you need to succeed. With the right business financing in place, we help you avoid cash flow challenges.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item">
                                    <h5 className="f-700 mar-b-30">Expand and advance your business</h5>
                                    <img src="/images/help/term.png" />
                                    <p className="mar-t-40">We provide you with the cash flow that businesses need in order to keep operating, enabling you to take advantage of opportunities for your business during your busy season.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item">
                                    <h5 className="f-700 mar-b-30">Maintain your cash flow</h5>
                                    <br />
                                    <img src="/images/help/application.png" />
                                    <p className="mar-t-40">We fund small, medium, and large businesses by purchasing your high quality receivables and collect directly from your customers. By selling credit-worthy invoices to us, your businesses receive immediate operating capital.</p>
                                </div>
                            </div>
                         </div>
                      </div>
                    </div>
                </section>
          <section className="section blue-b padd120" id="testimonials"  data-aos='fade-up'>
                  { editorLoaded ? (
                    <OwlCarousel
                        className="owl-theme"
                        margin={80}
                        items={1}
                        nav={false}
                        loop
                        stagePadding={400}
                    >
                        <div className="item">
                          <p className="white-c mar-b-20">“I wanted to thank you for your service and professionalism of LendonLand. You guys responded timely and were able to provide me the capital to complete my hospital development in an efficient manner.  You have been forthright, responsive, and backed up your word with what you said you were going to do. I look forward to working with you in the future on other projects. I would recommend your organization for someone else that is looking for working capital solutions on a short-term basis.”</p>
                          <p className="white-c"><strong>Fred Cohn</strong></p>
                          <p className="italic white-c">Funded by Lend on Land</p>
                        </div>
                        <div className="item">
                          <p className="white-c mar-b-20">“You took on this first of a kind project in Manhattan before anybody else did, and it's been much appreciated. Thank you for helping us get to this point.”</p>
                          <p className="white-c"><strong>Daniel Wise</strong></p>
                          <p className="italic white-c">Funded by Hard Money Mobile</p>
                        </div>
                        <div className="item">
                          <p className="white-c mar-b-20">“Thank you Dan Advance. It was a great experience to work with you. Your team is incredible. Despite my personal credit you agreed to help my business with a $244,000 fund which it desperately needed. You will be getting more businesses from my friends. Thank you for treating me in a kind way and listening to my story.”</p>
                          <p className="white-c"><strong>BC, LNT Inc.</strong></p>
                          <p className="italic white-c">Funded by Dan Advance</p>
                        </div>
                    </OwlCarousel>): (<div></div>)}
          </section>
          <section className="section CoverBanner padd140" id="partners" style={{backgroundImage:"url('/banner/home-partner.png')"}}  data-aos='fade-up'>
              <div className="container">
                <div className="row">
                   <div className="col-md-7">
                      <h1 className="f-800 blue-c mar-b-10">Become our partner!</h1>
                      <p className="mar-b-20">Your rich granddad would love to work with you! If you have friends, family, or anyone at all who wishes to work with your rich granddad too, simply introduce them to us and we will make sure you will get a piece of the pie.</p>
                      <Link to="/partner" className="btn btn_white">Become a Partner now</Link>
                   </div>
                </div>
              </div>
          </section>
        </main>
    </Layout>
  )
}
