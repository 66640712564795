import React, { Component } from 'react';
import { TextField,FormLabel,RadioGroup,Radio,FormControlLabel   } from '@material-ui/core';

import { useForm } from 'react-hook-form';
import axios from 'axios';

export default function EmpireFactors() {
    const [value, setValue] = React.useState('no');
    const { register, handleSubmit, errors,reset  } = useForm();
    const [succes, setSucces] = React.useState();   

    const onSubmit = (data, e) => {
      let formData = new FormData()

      formData.set("first_name", data.first_name)
      formData.set("last_name", data.last_name)
      formData.set("email", data.email)
      formData.set("legal_business_name", data.legal_business_name)
      formData.set("in_business_since", data.in_business_since)
      formData.set("tax_id", data.tax_id)
      formData.set("address", data.address)
      formData.set("city", data.city)
      formData.set("state", data.state)
      formData.set("zip", data.zip)
      formData.set("mobile_number", data.mobile_number)
      formData.set("website", data.website)
      formData.set("customer_profile", data.customer_profile)
      formData.set("business_state", data.business_state)
      formData.set("business_year", data.business_year)
      formData.set("legal_owner_name", data.legal_owner_name)
      formData.set("factor_per_month", data.factor_per_month)
      formData.set("monthly_sales_avg", data.monthly_sales_avg)
      formData.set("avg_invoice", data.avg_invoice)
      formData.set("existing_financing", data.existing_financing)
      formData.set("factored_before", data.factored_before)
      formData.set("platform", 'Rich Grand Dad')

      axios.post('https://api.empirefactors.com/api/webdeal', formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
       .then((response) => {
         e.target.reset();
         setSucces('Thank you for contacting us - we will get back to you soon!');
       }, (error) => {
         console.log(error);
       });

    };
    const handleChange = (event) => {
        setValue(event.target.value);
      };
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
        <div className="col-md-6">
           <TextField id="standard-basic" label="First Name" name="first_name" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.first_name && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Last Name" name="last_name" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.last_name && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Email" name="email" inputRef={register({
                     required: "This field is required",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                     }
                  })}/>
            <span className="error_field">{errors.email && errors.email.message}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Legal Business Name" name="legal_business_name" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.legal_business_name && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="In business since" name="in_business_since" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.in_business_since && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="EID/TaxID" name="tax_id" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.tax_id && "This field is required"}</span>
        </div>
        <div className="col-md-12">
           <TextField id="standard-basic" label="Address" rows={6}
            multiline  name="address" inputRef={register({ required: "This is required" })}/>
            <span className="error_field">{errors.address && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="City"  name="city" inputRef={register({ required: "This is required" })}/>
            <span className="error_field">{errors.city && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="State"  name="state" inputRef={register({ required: "This is required" })}/>
            <span className="error_field">{errors.state && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Zip"  name="zip" inputRef={register({ required: "This is required" })}/>
            <span className="error_field">{errors.zip && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Mobile Number"  name="mobile_number" inputRef={register({ required: "This is required" })}/>
            <span className="error_field">{errors.mobile_number && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Website"  name="website" inputRef={register({ required: "This is required" })}/>
            <span className="error_field">{errors.website && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Customer Profile"  name="customer_profile" inputRef={register({ required: "This is required" })}/>
            <span className="error_field">{errors.customer_profile && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Business State" name="business_state" inputRef={register({ required: "This is required" })}/>
            <span className="error_field">{errors.business_state && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Business Year" name="business_year" inputRef={register({ required: "This is required" })}/>
            <span className="error_field">{errors.business_year && "This field is required"}</span>
        </div>
        <div className="col-md-12">
           <TextField id="standard-basic" label="Legal Owner Name" rows={6}
               multiline name="legal_owner_name" inputRef={register({ required: "This is required" })}/>
               <span className="error_field">{errors.legal_owner_name && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="How much do you want to factor each months" name="factor_per_month" inputRef={register({ required: "This is required" })} type="number"/>
               <span className="error_field">{errors.factor_per_month && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Monthly sales average for last 3 month"  name="monthly_sales_avg" inputRef={register({ required: "This is required" })} type="number"/>
               <span className="error_field">{errors.monthly_sales_avg && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Average invoice size?"   name="avg_invoice" type="number" inputRef={register({ required: "This is required" })}/>
               <span className="error_field">{errors.avg_invoice && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Existing Financing"  name="existing_financing" type="number" inputRef={register({ required: "This is required" })}/>
               <span className="error_field">{errors.existing_financing && "This field is required"}</span>
        </div>
        <div className="col-md-12">
            <FormLabel component="legend">Have you factored before?</FormLabel>
            <RadioGroup aria-label="factored_before" name="factored_before" value={value} onChange={handleChange}>
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
        </div>
        <div className="col-md-12">
        <span className="succes_field">{succes}</span>
           <button className="btn_blue mar-t-30"><i className="icon icon-rocket"></i>Send</button>
        </div>
      </div>
      </form>
        )
}