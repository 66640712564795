import React, { Component } from 'react';
import { TextField,FormLabel,RadioGroup,Radio,FormControlLabel   } from '@material-ui/core';

import { useForm } from 'react-hook-form';
import axios from 'axios';

export default function SolidOakInvestment() {
    const [value, setValue] = React.useState('no');
    const { register, handleSubmit, errors,reset  } = useForm();
    const [succes, setSucces] = React.useState();

    const handleChange = (event) => {
        setValue(event.target.value);
      };

   const onSubmit = (data, e) => {
      let formData = new FormData()

      formData.set("first-name", data.first_name)
      formData.set("last-name", data.last_name)
      formData.set("email", data.email)
      formData.set("phone-number", data.phone)
      formData.set("b-street-address", data.street_address)
      formData.set("b-address-line-02", data.street_address_02)
      formData.set("b-city", data.city)
      formData.set("b-state", data.state)
      formData.set("b-country", data.country)
      formData.set("p-g-street-address", data.street_address_01_01)
      formData.set("p-g-address-line-02", data.street_address_01_02)
      formData.set("p-g-city", data.city_01)
      formData.set("p-g-state", data.state_01)
      formData.set("p-g-country", data.country_01)
      formData.set("s-p-street-address", data.street_address_02_01)
      formData.set("s-p-address-line-02", data.street_address_02_02)
      formData.set("s-p-city", data.city_02)
      formData.set("s-p-state", data.state_02)
      formData.set("s-p-country", data.country_02)
      formData.set("current-as", data.current_as)
      formData.set("loan-amount", data.desired_loan_amount)
      formData.set("radio-15", value)
      formData.set("proceeds", data.orginal_price)
      formData.set("summury", data.summary)
      formData.set("platform", 'Rich Grand Dad')

      axios.post('https://solidoakinvestments.com/index.php/wp-json/contact-form-7/v1/contact-forms/60/feedback', formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
       .then((response) => {
         e.target.reset();
         setSucces('Thank you for contacting us - we will get back to you soon!');
       }, (error) => {
         console.log(error);
       });
    };
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-6">
                   <TextField id="standard-basic" label="First Name" name="first_name" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.first_name && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Last Name" name="last_name" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.last_name && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="Email" name="email" inputRef={register({
                     required: "This field is required",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                     }
                  })}/>
                  <span className="error_field">{errors.email && errors.email.message}</span>
                 </div>
                 <div className="col-md-6">
                    <TextField id="standard-basic" label="Phone No." name="phone" inputRef={register({ required: "This is required" })}/>
                    <span className="error_field">{errors.phone && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                   <p class="f-700 primary-c no-margin uppercase mar-t-20">Borrowing Entity</p>
                 </div>
                 <div className="col-md-12">
                   <TextField id="standard-basic" label="Street Address" rows={6}
                            multiline name="street_address" inputRef={register({ required: "This is required" })}/>
                  <span className="error_field">{errors.street_address && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                    <TextField id="standard-basic" label="Address Line 2" rows={6}
                            multiline name="street_address_02" inputRef={register({ required: "This is required" })}/>
                    <span className="error_field">{errors.street_address_02 && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                   <TextField id="standard-basic" label="City" name="city" inputRef={register({ required: "This is required" })}/>
                   <span className="error_field">{errors.city && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                     <TextField id="standard-basic" label="State / Province / Region" name="state" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.state && "This field is required"}</span>
                 </div>
                 <div className="col-md-6">
                    <TextField id="standard-basic" label="Country" name="country" inputRef={register({ required: "This is required" })}/>
                    <span className="error_field">{errors.country && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                   <p class="f-700 primary-c no-margin uppercase mar-t-20">SUBJECT PROPERTY(IES)</p>
                 </div>
                 <div className="col-md-12">
                     <TextField id="standard-basic" label="Street Address" rows={6}
                            multiline name="street_address_01_01" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.street_address_01_01 && "This field is required"}</span>       
                 </div>
                 <div className="col-md-12">
                     <TextField id="standard-basic" label="Address Line 2" rows={6}
                            multiline name="street_address_01_02" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.street_address_01_02 && "This field is required"}</span> 
                 </div>
                 <div className="col-md-6">
                     <TextField id="standard-basic" label="City" name="city_01" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.city_01 && "This field is required"}</span> 
                 </div>
                 <div className="col-md-6">
                     <TextField id="standard-basic" label="State / Province / Region" name="state_01" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.state_01 && "This field is required"}</span> 
                 </div>
                 <div className="col-md-6">
                     <TextField id="standard-basic" label="Country" name="country_01" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.country_01 && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                    <p class="f-700 primary-c no-margin uppercase mar-t-20">PROPERTY GUARANTORS</p>
                 </div>
                 <div className="col-md-12">
                     <TextField id="standard-basic" label="Street Address" rows={6}
                            multiline name="street_address_02_01" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.street_address_02_01 && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                     <TextField id="standard-basic" label="Address Line 2" rows={6}
                            multiline name="street_address_02_02" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.street_address_02_02 && "This field is required"}</span>       
                 </div>
                 <div className="col-md-6">
                     <TextField id="standard-basic" label="City" name="city_02" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.city_02 && "This field is required"}</span>  
                 </div>
                 <div className="col-md-6">
                     <TextField id="standard-basic" label="State / Province / Region" name="state_02" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.state_02 && "This field is required"}</span> 
                 </div>
                 <div className="col-md-6">
                     <TextField id="standard-basic" label="Country" name="country_02" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.country_02 && "This field is required"}</span> 
                 </div>
                 <div className="col-md-12">
                     <p class="f-700 primary-c no-margin uppercase mar-t-20">CURRENT AS - IS VALUE</p>
                 </div>
                 <div className="col-md-12">
                     <TextField id="standard-basic" label="USD" name="current_as" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.current_as && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                     <p class="f-700 primary-c no-margin uppercase mar-t-20">DESIRED LOAN AMOUNT</p>
                 </div>
                 <div className="col-md-12">
                     <TextField id="standard-basic" label="USD"  name="desired_loan_amount" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.desired_loan_amount && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                     <RadioGroup aria-label="desired_loan_amount_ra" value={value} onChange={handleChange} name="desired_loan_amount_ra" >
                        <FormControlLabel value="BK's" control={<Radio />} label="BK's" />
                        <FormControlLabel value="Foreclosures" control={<Radio />} label="Foreclosures" />
                        <FormControlLabel value="Judgements" control={<Radio />} label="Judgements" />
                        <FormControlLabel value="Tax Liens" control={<Radio />} label="Tax Liens" />
                     </RadioGroup>
                     <span className="error_field">{errors.desired_loan_amount_ra && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                     <p class="f-700 primary-c no-margin uppercase mar-t-20">ORIGINAL PRICE AND DATE PURCHASED (IF OWNED): USE OF PROCEEDS:</p>
                 </div>
                 <div className="col-md-12">
                     <TextField id="standard-basic" label="Type here" rows={6}
                            multiline name="orginal_price" inputRef={register({ required: "This is required" })}/>
                     <span className="error_field">{errors.orginal_price && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                     <p class="f-700 primary-c no-margin uppercase mar-t-20">SUMMARY & EXIT STRATEGY AND YOUR CONTACT PHONE NUMBER</p>
                 </div>
                 <div className="col-md-12">
                    <TextField id="standard-basic" label="Type here" rows={6}
                            multiline name="summary" inputRef={register({ required: "This is required" })}/>

                    <span className="error_field">{errors.summary && "This field is required"}</span>
                 </div>
                 <div className="col-md-12">
                     <span className="succes_field">{succes}</span>
                     <button className="btn_blue mar-t-30"><i className="icon icon-rocket"></i>Send</button>
                 </div>
               </div>
         </form>
        )
}