import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout'
import { BrowserRouter as Router, Route, Link,useParams } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function NewsSingle({ title, body }) {
    const [posts, setPosts] = useState([]);
    const { slug }= useParams()
    useEffect(() => {
        async function loadPosts() {
            const response = await fetch('https://richgranddad.com/api/wp-json/wp/v2/posts/?slug='+slug);
            if(!response.ok) {
                // oups! something went wrong
                return;
            }
    
            const posts = await response.json();
            setPosts(posts);
        }
    
        loadPosts();
    }, [])
    return (
        <Layout>
            
            <main>
               <section id="news" className="padd40 relative no-padd-bottom news">
               {posts.map((post, index) => (
                   <div>
                       <Helmet>
                            <title>{post.title.rendered } | Rich Granddad</title>
                        </Helmet>
                   <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <h2 className="f-800 rustin-black mar-b-30" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h2>
                      </div>
                    </div>
                  </div>
                  <div className="news_list mar-t-10 mar-b-50" data-aos="fade-up">
                      <div className="container">
                         <div className="row">
                            <div className="col-md-12">
                                <img src={post.acf.news_image} alt={post.title.rendered}/>
                                <p dangerouslySetInnerHTML={{ __html: post.acf.summarize_content }} className="mar-t-20 mar-b-20"/>
                                <Link to={'/news'}>
                                        Back to News Page ...
                                </Link>
                            </div>
                         </div>
                      </div>
                  </div>
                  </div>
                  ))} 
               </section>
            </main>
        </Layout>
        
    )
}
