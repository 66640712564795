import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { TextField,FormLabel,RadioGroup,Radio,FormControlLabel   } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

import { useForm } from 'react-hook-form';
import axios from 'axios';


export default function LendonLand() {
    const [value, setValue] = React.useState('no');
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [purchasedDate, setPurchasedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const { register, handleSubmit, errors,reset  } = useForm();
    const [succes, setSucces] = React.useState();

    const handleChange = (event) => {
      setValue(event.target.value);
    };
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
    const handleDateChange1 = (date) => {
      setPurchasedDate(date);
    };
    const onSubmit = (data, e) => {
      let formData = new FormData()
      formData.set("are_you_broker", value)
      formData.set("first_name", data.first_name)
      formData.set("sur_name", data.surname)
      formData.set("email", data.email)
      formData.set("name_of_borrowing_entity", data.name_of_borrowing_entity)
      formData.set("industry_type", data.industry_type)
      formData.set("loan_amount", data.loan_amount)
      formData.set("property_type", data.property_type)
      formData.set("property_address", data.property_address)
      formData.set("phone_number", data.phone_number)
      formData.set("mobile_number", data.mobile_number)
      formData.set("total_collateral_value", data.total_collateral_value)
      formData.set("gross_monthly_revenue", data.gross_monthly_revenue)
      formData.set("any_existing_cash_advances", data.any_existing_cash_advances)
      formData.set("business_inception_date", selectedDate)
      formData.set("date_property_purchased", purchasedDate)
      formData.set("actual_cash_on_hand_to_invest", data.actual_cash_on_hand_to_invest)
      formData.set("are_there_any_liens_mortgages_on_the_property", data.are_there_any_liens_mortgages_on_the_property)
      formData.set("any_other_investment_property_owned", data.any_other_investment_property_owned)
      formData.set("when_is_closing_needed", data.when_is_closing_needed)
      formData.set("summary_of_loan_request", data.summary_of_loan_request)
      formData.set("platform", 'Rich Grand Dad')

      axios.post('https://lendonland.com/index.php/wp-json/contact-form-7/v1/contact-forms/1324/feedback', formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
       .then((response) => {
         e.target.reset();
         setSucces('Thank you for contacting us - we will get back to you soon!');
       }, (error) => {
         console.log(error);
       });
   };  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
               <div className="col-md-12">
                  <FormLabel component="legend">Are you a broker?</FormLabel>
                     <RadioGroup aria-label="are_you_broker" name="are_you_broker" value={value} onChange={handleChange}>
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
               </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="First Name" required  name="first_name" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.first_name && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Surname" required name="surname" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.surname && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Email" name="email" inputRef={register({
                     required: "This field is required",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                     }
                  })}/>
            <span className="error_field">{errors.broker_email && errors.broker_email.message}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Name of Borrowing Entity"  name="name_of_borrowing_entity" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.name_of_borrowing_entity && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Industry type" name="industry_type" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.industry_type && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Loan Amount" name="loan_amount" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.loan_amount && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-12">
                                       <TextField id="standard-basic" label="Property type" name="property_type" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.property_type && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-12">
                                       <TextField id="standard-basic" label="Property Address" rows={6}
                            multiline name="property_address" inputRef={register({ required: "This is required" })}/>
                            <span className="error_field">{errors.property_address && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Phone Number" name="phone_number" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.phone_number && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Mobile Number" name="mobile_number" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.mobile_number && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Total Collateral Value" name="total_collateral_value" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.total_collateral_value && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Gross Monthly Revenue" name="gross_monthly_revenue" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.gross_monthly_revenue && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-12">
                                       <TextField id="standard-basic" label="Any Existing Cash Advances" rows={6}
                            multiline name="any_existing_cash_advances" inputRef={register({ required: "This is required" })}/>
                            <span className="error_field">{errors.any_existing_cash_advances && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="business_inception_date"
                                            label="Business Inception Date"
                                            format="MM/dd/yyyy"
                                            name="business_inception_date" 
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                              'aria-label': 'change date',
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-6">
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date_property_purchased"
                                            label="Date Property Purchased"
                                            format="MM/dd/yyyy"
                                            value={purchasedDate}
                                            name="date_property_purchased" 
                                            onChange={handleDateChange1}
                                            KeyboardButtonProps={{
                                              'aria-label': 'change date',
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Actual Cash on Hand to Invest" name="actual_cash_on_hand_to_invest" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.actual_cash_on_hand_to_invest && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Are There any Liens/ Mortgages on the Property?" name="are_there_any_liens_mortgages_on_the_property" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.are_there_any_liens_mortgages_on_the_property && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="Any Other Investment Property Owned?" name="any_other_investment_property_owned" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.any_other_investment_property_owned && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-6">
                                       <TextField id="standard-basic" label="When is Closing Needed?" name="when_is_closing_needed" inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.when_is_closing_needed && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-12">
                                       <TextField id="standard-basic" label="Summary of Loan Request" rows={6}
                            multiline name="summary_of_loan_request" inputRef={register({ required: "This is required" })}/>
                            <span className="error_field">{errors.summary_of_loan_request && "This field is required"}</span>
                                    </div>
                                    <div className="col-md-12">
                                    <span className="succes_field">{succes}</span>
                                       <button className="btn_blue mar-t-30"><i className="icon icon-rocket"></i>Send</button>
                                    </div>
                                  </div></form>
        )
}