import React, { useState, useEffect, useRef } from 'react'
import Script from 'react-load-script'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import AOS from 'aos';
import '../scripts/inertiaScroll'

import { slide as Menu } from 'react-burger-menu'

export default class Layout extends React.Component {
  constructor() {
    super();
    this.state = { luxy: [],link_:'',scrolled:0 };
  }
  componentDidMount() {
    AOS.init({
      duration : 2000
    })
    if (typeof window !== 'undefined') {
      this.setState({ link_: window.location.pathname })
    }
    //window.$(".box").inertiaScroll();
  }
  componentWillUnmount() {
  }
  render () {
    return (
      <div>
        <div>
          <header className="header" data-aos='fade-in'>
            <div className="header-top">
              <div className="mobile_menu">
                <Menu right>
                  <ul className="header-nav-mobile">
                      <li className={this.state.link_ == "/funding_solutions" ? "active_nav" : ""} >
                        <Link to="/funding_solutions">
                          FUNDING SOLUTIONS
                        </Link>
                      </li>
                      <li className={this.state.link_ == "/faq" ? "active_nav" : ""}>
                        <Link to="/faq">
                          FAQ’s
                        </Link>
                      </li>
                      <li className={this.state.link_ == "/about" ? "active_nav" : ""}>
                        <Link to="/about">
                          ABOUT US
                        </Link>
                      </li>
                      <li className={this.state.link_ == "/news" ? "active_nav" : ""}>
                        <Link to="/news">
                          NEWS
                        </Link>
                      </li>
                      <li className={this.state.link_ == "/contact" ? "active_nav" : ""}>
                        <Link to="/contact">
                          CONTACT US
                        </Link>
                      </li>
                    </ul>
                </Menu>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-2">
                  <div className="brand-logo">
                    <Link to="/">
                      <img src="../images/logo.svg" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-7 none-md">
                  <nav>
                    <ul className="header-nav">
                      <li className={this.state.link_ == "/funding_solutions" ? "active_nav" : ""} >
                        <Link to="/funding_solutions">
                          FUNDING SOLUTIONS
                        </Link>
                      </li>
                      <li className={this.state.link_ == "/faq" ? "active_nav" : ""}>
                        <Link to="/faq">
                          FAQ’s
                        </Link>
                      </li>
                      <li className={this.state.link_ == "/about" ? "active_nav" : ""}>
                        <Link to="/about">
                          About us
                        </Link>
                      </li>
                      <li className={this.state.link_ == "/news" ? "active_nav" : ""}>
                        <Link to="/news">
                          News
                        </Link>
                      </li>
                      <li className={this.state.link_ == "/contact" ? "active_nav" : ""}>
                        <Link to="/contact">
                          CONTACT US
                        </Link>
                      </li>
                    </ul>
                    
                  </nav>
                </div><div className="col-md-3 none-md">
                  <div className="header-contact">
                      <a to="tel:(909)742-4323">CALL NOW : (909) RICH-DAD</a>
                  </div>
                </div>
              </div>
            </div>
          </header>
          
          {this.props.children}
          <footer className="footer padd80"  data-aos='fade-up' id="footer">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <table>
                   <tbody>
                    <tr>
                      <td><i className="icon icon-address"></i></td>
                      <td>
                        <address className="no-mar mar-b-30">
                          <p className="white-c"><strong>Rich Granddad</strong><br/>C/O COHEN SABAN <br/>112 W34th Street (18th floor)<br/>New York, NY 10120</p>
                        </address>
                      </td>
                    </tr>
                    <tr>
                      <td><i className="icon icon-telephone"></i></td>
                      <td>
                        <p className="white-c mar-b-30"><strong>Telephone</strong> : <a href="tell:(909)742-4323">(909) RICH-DAD</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td><i className="icon icon-email"></i></td>
                      <td>
                        <p className="white-c"><strong>Email</strong> : <a href="mailto:info@richgranddad.com" className="white-c">info@richgranddad.com</a></p>
                      </td>
                    </tr>
                   </tbody>
                  </table>
                </div>
                <div className="col-md-4">
                  <span className="icon_link_span"><i className="icon icon-link"></i></span>
                  <ul className="footer_link">
                    <li>
                      <Link to="/funding_solutions">
                        <p className="white-c uppercase">FUNDING SOLUTIONS</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq">
                      <p className="white-c uppercase">FAQ'S</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/apply">
                      <p className="white-c uppercase">APPLY NOW</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/about">
                      <p className="white-c uppercase">ABOUT US</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        <p className="white-c uppercase">CONTACT US</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/partner">
                        <p className="white-c uppercase">BECOME A PARTNER</p>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <p className="f-700 white-c">Connect with us on:</p>
                  <ul className="connect_link">
                    <li>
                      <a href="https://www.instagram.com/richgranddad/"><i className="icon icon-insta" target="_blank"></i></a>
                    </li>
                    <li>
                      <a href="whatsapp://send?text=Hello&phone=+13475645090"  target="_blank"><i className="icon icon-whatapp"></i></a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/rich-grand-dad/"  target="_blank"><i className="icon icon-in"></i></a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/RichGrandDad"  target="_blank"><i className="icon icon-facebook"></i></a>
                    </li>
                  </ul>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-4"></div>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <p className="small-title white-c">© Richgranddad.com. 2021. All Rights Reserved</p>
                </div>
              </div>
            </div>
          </footer>
          
        </div>
      </div>
    )
  }
}