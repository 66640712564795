import React, { useState, useEffect, useRef } from 'react'
import Layout from '../components/layout'
import Partners from '../components/partners'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function FundingSolutions() {
    return (
        <Layout>
            <Helmet>
              <title>Funding Solutions | Rich Granddad</title>
            </Helmet>
            <main>
                <div style={{backgroundImage:"url('/banner/funding_solutions.png')"}} className="SubBanner" data-aos='fade-in'>
                    <div className="BannerContainWrapper">
                        <div className="BannerContainSub">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 luxy-el" data-aos='fade-up'>
                                        <h1 className="f-800 rustin-red mar-b-20">Funding for<br /> various needs</h1>
                                        <h5 className="f-800 soft-red mar-b-10">Your rich granddad's wallet offers <br />a range of funding solutions</h5>
                                        <p className="f-500  mar-b-40">Just let your rich granddad know what you need! </p>
                                        <Link to="/apply" className="btn btn-default">Apply Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="FundingCompany">
                    <div className="FundingCompanyList">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4" data-aos="fade-right">
                                    <div className="FLogo">
                                        <img src="/images/lend-on-land.png" />
                                    </div>
                                </div>
                                <div className="col-md-8" data-aos="fade-left">
                                    <h5 className="f-800 mar-b-20">Working capital for businesses with property</h5>
                                    <p className="line35 f-500">Lend on Land is a private lender that funds businesses based on equity valuation of their assets/property. We understand that each unique circumstance requires special attention and flexible underwriting guidelines that get the funds into your account in a matter of days. We give out funding in the range of $5K – $1.5 million for a period of six to thirty six months.</p>
                                    <ul className="toolBar">
                                        <li>
                                        <a href="https://lendonland.com/" className="btn_under" target="_blank">Visit Website</a>
                                        </li>
                                        <li>
                                        <Link to="/apply/lendonland" className="btn btn-default">Apply Now</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FundingCompanyList">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4" data-aos="fade-right">
                                    <div className="FLogo">
                                        <img src="/images/hard-money.png" />
                                    </div>
                                </div>
                                <div className="col-md-8" data-aos="fade-left">
                                    <h5 className="f-800 mar-b-20">Commercial mortgage brokerage</h5>
                                    <p className="line35 f-500">Hard Money Mobile is a leader in the realm of direct lending, with closing times typically within two weeks, and a funding commitment issued within 48 hours. We strive to benefit clients who want to take advantage of opportunities requiring immediate action as well as clients that are facing financial crises, such as foreclosure bailout or bankruptcy filing. Since funding commitments can be issued within 48 hours, our borrowers can proceed with their transactions, assured that a banking partner is present. All origination fees, including the commitment fee, are paid at closing.</p>
                                    <ul className="toolBar">
                                        <li>
                                            <a href="https://hardmoneymobile.com/" className="btn_under" target="_blank">Visit Website</a>
                                        </li>
                                        <li>
                                        <Link to="/apply/hardmoneymobile" className="btn btn-default">Apply Now</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FundingCompanyList">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4" data-aos="fade-right">
                                    <div className="FLogo">
                                        <img src="/images/solid-oak.png" />
                                    </div>
                                </div>
                                <div className="col-md-8" data-aos="fade-left">
                                    <h5 className="f-800 mar-b-20">Commercial equity and debt financing</h5>
                                    <p className="line35 f-500">Solid Oak Investments was created to provide a true bridge to bank loans. Through innovation and experience within both the commercial and private sectors, Solid Oak Investments’ founders bring an expertise in financing and placing commercial loans with modern technology and quick turn around.</p>
                                    <ul className="toolBar">
                                        <li>
                                        <a href="https://solidoakinvestments.com/" className="btn_under" target="_blank">Visit Website</a>
                                        </li>
                                        <li>
                                        <Link to="/apply/solidoakinvestment" className="btn btn-default">Apply Now</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FundingCompanyList">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4" data-aos="fade-right">
                                    <div className="FLogo">
                                        <img src="/images/empire-factors.png" />
                                    </div>
                                </div>
                                <div className="col-md-8" data-aos="fade-left">
                                    <h5 className="f-800 mar-b-20">Commercial invoice factoring</h5>
                                    <p className="line35 f-500">Empire Factors is a bonded trade finance lender in New York and New Jersey which provides funding to commercial businesses. Empire Factors is a primary provider of commercial financing solutions for all types of businesses, offering a diverse set of business financing options. As a direct funder, and in conjunction with key affiliates, we offer accounts receivable financing, supply chain financing, equipment financing, merchant cash advance, purchase order financing, revenue loans and term loans.</p>
                                    <ul className="toolBar">
                                        <li>
                                        <a href="https://empirefactors.com/" className="btn_under" target="_blank">Visit Website</a>
                                        </li>
                                        <li>
                                        <Link to="/apply/empirefactors" className="btn btn-default">Apply Now</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FundingCompanyList">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4" data-aos="fade-right">
                                    <div className="FLogo">
                                        <img src="/images/dan-advance.png" />
                                    </div>
                                </div>
                                <div className="col-md-8" data-aos="fade-left">
                                    <h5 className="f-800 mar-b-20">Merchant cash advance</h5>
                                    <p className="line35 f-500">Dan Advance is the answer to small business financing. Located in the heart of the financial district in New York City, Dan Advance has a dedicated team that helps fund thousands of merchants in practically every industry. If you are a restaurant owner, a veterinarian, a salon owner, or own an auto repair business, we are here to help you.</p>
                                    <ul className="toolBar">
                                        <li>
                                        <a href="https://danadvance.com/" className="btn_under" target="_blank">Visit Website</a>
                                        </li>
                                        <li>
                                        <Link to="/apply/danadvance" className="btn btn-default">Apply Now</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FundingCompanyList">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4" data-aos="fade-right">
                                    <div className="FLogo">
                                        <img src="/images/ccp.png" />
                                    </div>
                                </div>
                                <div className="col-md-8" data-aos="fade-left">
                                    <h5 className="f-800 mar-b-20">Commercial real estate loans</h5>
                                    <p className="line35 f-500">Located in Northern New Jersey, Century Capital Partners LLC is a commercial real estate lender, offering hard money loans to companies seeking funding from sources other than traditional banks. Its goal is to provide fast and efficient solutions to your financing needs.</p>
                                    <ul className="toolBar">
                                        <li>
                                        <a href="https://www.centurycapllc.com/" className="btn_under" target="_blank">Visit Website</a>
                                        </li>
                                        <li>
                                        <Link to="/apply/centurycapitalpartners" className="btn btn-default">Apply Now</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FundingCompanyList">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4" data-aos="fade-right">
                                    <div className="FLogo">
                                        <img src="/images/quantak.png" />
                                    </div>
                                </div>
                                <div className="col-md-8" data-aos="fade-left">
                                    <h5 className="f-800 mar-b-20">Global outsourcing service</h5>
                                    <p className="line35 f-500">Quantak is a global outsourcing services provider designed to help your business achieve its full potential. From first class marketing solutions to impeccable website development and design, Quantak offers you a complete portfolio of solutions to grow your business.</p>
                                    <ul className="toolBar">
                                        <li>
                                        <a href="http://quantak.com/" className="btn_under" target="_blank">Visit Website</a>
                                        </li>
                                        <li>
                                        <Link to="/apply/quantak" className="btn btn-default">Apply Now</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section padd110" id="help"  data-aos="fade-up">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="f-800 rustin-black mar-b-10">How We Help Businesses</h1>
                            </div>
                        </div>
                    </div>
                    <div className="helpItem mar-t-40">
                      <div className="container">
                         <div className="row">
                         <div className="col-md-4">
                                <div className="item">
                                    <h5 className="f-700 mar-b-30">Obtain capital</h5>
                                    <br />
                                    <img src="/images/help/boss.png" />
                                    <p className="mar-t-40">We help small business owners and entrepreneurs obtain the capital you need to succeed. With the right business financing in place, we help you avoid cash flow challenges.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item">
                                    <h5 className="f-700 mar-b-30">Expand and advance your business</h5>
                                    <img src="/images/help/term.png" />
                                    <p className="mar-t-40">We provide you with the cash flow that businesses need in order to keep operating, enabling you to take advantage of opportunities for your business during your busy season.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item">
                                    <h5 className="f-700 mar-b-30">Maintain your cash flow</h5>
                                    <br />
                                    <img src="/images/help/application.png" />
                                    <p className="mar-t-40">We fund small, medium, and large businesses by purchasing your high quality receivables and collect directly from your customers. By selling credit-worthy invoices to us, your businesses receive immediate operating capital.</p>
                                </div>
                            </div>
                         </div>
                      </div>
                    </div>
                </section>
                <Partners />
            </main>
        </Layout>
    )
}
