import React, { useEffect, useState } from 'react';
import Layout from '../components/layout'
import { EqualHeight, EqualHeightElement } from 'react-equal-height';
import Partners from '../components/partners'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function News() {
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        async function loadPosts() {
            const response = await fetch('https://richgranddad.com/api/wp-json/wp/v2/posts');
            if(!response.ok) {
                // oups! something went wrong
                return;
            }
    
            const posts = await response.json();
            setPosts(posts);
        }
    
        loadPosts();
    }, [])
    return (
        <Layout>
            <Helmet>
              <title>News | Rich Granddad</title>
            </Helmet>
            <main>
               <section id="news" className="padd40 relative no-padd-bottom news">
                   <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <h2 className="f-800 rustin-black mar-b-30" data-aos="fade-up">Our Latest Deals</h2>
                      </div>
                    </div>
                  </div>
                  <div className="news_list mar-t-10" data-aos="fade-up">
                      <div className="container">
                         <div className="row">
                          <EqualHeight>
                            {posts.map((post, index) => (
                                <div className="col-md-6">
                                    <EqualHeightElement name="Name">
                                        <h6 dangerouslySetInnerHTML={{ __html: post.title.rendered }} className="f-700 rustin-black mar-b-20"/>
                                    </EqualHeightElement>
                                    <img src={post.acf.news_image} alt={post.title.rendered}/>
                                    <EqualHeightElement name="Contain">
                                        <p dangerouslySetInnerHTML={{ __html: post.acf.summarize_content }} className="mar-t-20"/>
                                    </EqualHeightElement>
                                    <br />
                                    <Link to={'/news/'+post.slug}>
                                        Read More ...
                                    </Link>
                                    <hr  style={{ borderTop: '1px solid #969696' }} className="mar-t-30 mar-b-30"/>
                                </div>
                            ))}    
                           </EqualHeight>
                         </div>
                      </div>
                  </div>
               </section>
            </main>
        </Layout>
        
    )
}
