import React, { Component } from 'react';
import Layout from '../components/layout'
import Partners from '../components/partners'
import { TextField,FormLabel,RadioGroup,Radio,FormControlLabel   } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {Helmet} from "react-helmet";

export default function Contact() {
    const { register, handleSubmit, errors,reset  } = useForm();
    const [succes, setSucces] = React.useState();


    const onSubmit = (data, e) => { 
      let formData = new FormData()
      formData.set("full-name", data.full_name)
      formData.set("email-address", data.email_address)
      formData.set("phone-number", data.phone_number)
      formData.set("message", data.message)
      axios.post('https://richgranddad.com/api/index.php/wp-json/contact-form-7/v1/contact-forms/5/feedback', formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
       .then((response) => {
         e.target.reset();
         setSucces('Thank you for contacting us - we will get back to you soon!');
       }, (error) => {
         console.log(error);
       });
    };
    return (
        <Layout>
            <Helmet>
              <title>Contact Us | Rich Granddad</title>
            </Helmet>
            <main>
            <div style={{backgroundImage:"url('/banner/contact-us.png')"}} className="SubBanner" data-aos='fade-in'>
                    <div className="BannerContainWrapper">
                        <div className="BannerContainSub">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 luxy-el" data-aos='fade-up'>
                                        <h1 className="f-800 rustin-red mar-b-20">Contact Us</h1>
                                        <h5 className="f-800 soft-red mar-b-10">Your rich granddad's wallet offers <br />a range of Funding solutions</h5>
                                        <p className="f-500  mar-b-40">Just let your rich granddad know what you need! </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <section className="white-blue-b padd50 contactUs">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6" data-aos='fade-right'>
                        <p className="mar-b-10 mar-t-30">If you wish to consult on what product is best for you,
please fill in the form below</p>
                        <form  noValidate autoComplete="off" className="mar-t-20"  onSubmit={handleSubmit(onSubmit)}> 
                        <div className="row">
                          <div className="col-md-12">
                            <TextField id="standard-basic" label="Full Name" name="full_name" inputRef={register({ required: "This is required" })}/>
                            <span className="error_field">{errors.full_name && "This field is required"}</span>
                          </div>
                          <div className="col-md-12">
                            <TextField id="standard-basic" label="Email Address" name="email_address"  inputRef={register({
                              required: "This field is required",
                              pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "invalid email address"
                              }
                            })}/>
                            <span className="error_field">{errors.email_address && errors.email_address.message}</span>
                          </div>
                          <div className="col-md-12">
                            <TextField id="standard-basic" label="Phone Number" name="phone_number" inputRef={register({ required: "This is required" })}/>
                            <span className="error_field">{errors.phone_number && "This field is required"}</span>
                          </div>
                          <div className="col-md-12">
                            <TextField
                              id="standard-textarea"
                              label="Message"
                              placeholder="Message"
                              name="message"
                              rows={6}
                              multiline
                              inputRef={register({ required: "This is required" })}
                            /> 
                            <span className="error_field">{errors.message && "This field is required"}</span>
                          </div>
                          <div className="col-md-12">
                            <span className="succes_field">{succes}</span>
                            <button className="btn_blue mar-t-30"><i className="icon icon-rocket"></i>Send</button>
                        </div>
                        </div>  
                        
                        </form>
                      </div><div className="col-md-6" data-aos='fade-left'>
                        <aside className="sidebar mar-t-30">
                            <p className="tag">Call us <a href="tel:(909)742-4323">(909) RICH-DAD</a> or <br />email us via the online form </p>

                            <p>Office Hours</p>
                            <p><span>Monday through Thursday</span><br />
                            8am to 8pm (EST)</p>

                            <p><span>Friday</span><br />
                            8am to 4pm (EST)</p>
                        </aside>
                      </div>
                    </div>
                  </div>
              </section>
              <Partners />
            </main>
        </Layout>
    )
}
