import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppRouter } from './route/index';
import './styles/globals.scss';
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    
  });
  return (
      <AppRouter />
  )
}

export default App;
