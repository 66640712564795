import { useForm } from 'react-hook-form';
import axios from 'axios';
import { TextField} from '@material-ui/core';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState } from 'react';

export default function Partners() {
    const { register, handleSubmit, errors,reset  } = useForm();
    const [succes, setSucces] = React.useState();

    const onSubmit = (data, e) => {
        let formData = new FormData()
  
        formData.set("email", data.email)
        let ajax = 'https://richgranddad.com/api/newsletter/';
        axios.post(ajax , formData).then(response => {
            if(response.data.status == 'mail_sent'){
                setSucces(response.data.message);    
            }else{
              setSucces(response.data.message);    
            }
          }).catch(error => {
            setSucces('Something went wrong. Please try again.');  
          });

    };
    return (
        <div>
        <section className="section CoverBanner padd140" id="partners" style={{backgroundImage:"url('/banner/home-partner.png')"}}  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <h1 className="f-800 blue-c mar-b-10">Become our partner!</h1>
                        <p className="mar-b-20">Your rich granddad would love to work with you! If you have friends, family, or anyone at all who wishes to work with your rich granddad too, simply introduce them to us and we will make sure you will get a piece of the pie. </p>
                        <Link to="/partner" className="btn btn_white">Become a Partner now</Link>
                    </div>
                </div>
            </div>
        </section>
        <section className="soft-white-b padd80 CoverBannerCn" style={{backgroundImage:"url('/images/letter.png')"}}  data-aos="fade-up">
            <div className="NewsLetter">
                <div className="container">
                   <div className="row">
                      <div className="col-md-5 display-inline">  
                        <div className="news-letter-form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <TextField id="email" label="Enter your email address here" name="email"  inputRef={register({ required: "This is required" })} className="form-control form-control-withoutbd"/>
                                <span className="succes_field">{succes}</span>
                                <button className="btn_blue"><i className="icon icon-rocket"></i>Send</button>
                            </form>    
                        </div>
                      </div>
                      <div className="col-md-7 display-inline">
                          <h1 className="f-800 blue-c">Get regular updates</h1>  
                          <p className="mar-t-20 blue-c f-600">Stay in touch with your rich granddad</p>
                      </div>
                    </div> 
                </div>
            </div>
        </section>
        </div> 
    )
}