import React, { Component } from 'react';
import { TextField,FormLabel,RadioGroup,Radio,FormControlLabel   } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/date-fns';

import { useForm } from 'react-hook-form';
import axios from 'axios';

export default function Quantak() {
    const [occupancy, setOccupancy] = React.useState('owner');
    const [areThereAnyLiensOnProperty, setareThereAnyLiensOnProperty] = React.useState('no');
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const { register, handleSubmit, errors,reset  } = useForm();
    const [succes, setSucces] = React.useState();

    const areThereAnyLiensOnPropertyChange = (event) => {
        setareThereAnyLiensOnProperty(event.target.value);
      };
    const occupancyChange = (event) => {
      setOccupancy(event.target.value);
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
      };
   const onSubmit = (data, e) => {
      let formData = new FormData()

      formData.set("subject", data.subject)
      formData.set("fullname", data.full_name)
      formData.set("phone", data.telephone)
      formData.set("email", data.email)
      formData.set("details", data.inquiry)
      formData.set("platform", 'Rich Grand Dad')

      axios.post('https://quantak.com/index.php/wp-json/contact-form-7/v1/contact-forms/1378/feedback', formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
       .then((response) => {
         e.target.reset();
         setSucces('Thank you for contacting us - we will get back to you soon!');
       }, (error) => {
         console.log(error);
       });
    };
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <div className="row">
               <div className="col-md-6">
                  <TextField name="subject" id="standard-basic" label="Subject"   inputRef={register({ required: "This is required" })}/>
                  <span className="error_field">{errors.subject && "This field is required"}</span>
               </div>
               <div className="col-md-6">
                  <TextField name="full_name" id="standard-basic" label="Full Name" inputRef={register({ required: true })}/>
                  <span className="error_field">{errors.full_name && "This field is required"}</span>
               </div>
               <div className="col-md-6">
                  <TextField name="telephone" id="standard-basic" label="Telephone No"  inputRef={register({ required: true })}/>
                  <span className="error_field">{errors.telephone && "This field is required"}</span>
               </div>
               <div className="col-md-6">
                  <TextField name="email" id="standard-basic" label="Email"  inputRef={register({
                     required: "This field is required",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                     }
                  })}/>
                  <span className="error_field">{errors.email && errors.email.message}</span>
               </div>
               <div className="col-md-12">
                  <TextField name="inquiry" id="standard-basic" label="Type your inquiry" rows={6} inputRef={register({ required: true })}
         multiline/>
                 <span className="error_field">{errors.inquiry && "This field is required"}</span>
               </div>

               <div className="col-md-12">
                  <span className="succes_field">{succes}</span>
                  <button className="btn_blue mar-t-30"><i className="icon icon-rocket"></i>Send</button>
               </div>
         </div>
      </form>
        )
}