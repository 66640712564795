import React, { Component } from 'react';
import Layout from '../components/layout'
import Partners from '../components/partners'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function About() {
    return (
        <Layout>
           <Helmet>
              <title>About Us | Rich Granddad</title>
            </Helmet>
            <main>
                <div style={{backgroundImage:"url('/banner/about.png')"}} className="SubBanner" data-aos='fade-in'>
                    <div className="BannerContainWrapper">
                        <div className="BannerContainSub">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 luxy-el" data-aos='fade-up'>
                                        <h1 className="f-800 rustin-red mar-b-20">Funding for<br /> various needs</h1>
                                        <h5 className="f-800 soft-red mar-b-10">Your rich granddad's wallet offers <br />a range of funding solutions</h5>
                                        <p className="f-500  mar-b-40">Your rich granddad is an industrious businessman with his fingers<br/> in many pies. His many companies have helped more than 100+ <br/>companies with bridge loans, construction loans, cash advances, <br/>invoice factoring, etc. </p>
                                        <Link to="/apply" className="btn btn-default">Apply Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="HowWeHelp" className="padd110 relative HowWeHelp">
                   <div className="HowWeHelpBG" style={{backgroundImage:"url('/images/about.png')"}} data-aos='fade-right'>

                   </div>
                   <div className="container" data-aos='fade-left'>
                      <div className="row">
                        <div className="col-md-5"></div>
                        <div className="col-md-7 luxy-el">
                          <h2 className="f-800">How We Help Businesses</h2>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-md-5"></div>
                        <div className="col-md-7">
                          <ul>
                             <ll>
                                <h6 className="f-700 mar-b-10 mar-t-30">Obtain capital</h6>
                                <p className=" f-500 dark-gray small-title">We help small business owners and entrepreneurs obtain the capital you need to succeed. With the right business financing in place, we help you avoid cash flow challenges. </p>
                                <div className="clearfix"></div>
                             </ll>
                             
                             <ll>
                                <h6 className="f-700 mar-b-10 mar-t-30">Expand and advance your business</h6>
                                <p className=" f-500 dark-gray small-title">We provide you with the cash flow that businesses need in order to keep operating, enabling you to take advantage of opportunities for your business during your busy season.</p>
                             </ll>
                             <ll>
                                <h6 className="f-700 mar-b-10 mar-t-30">Maintain your cash flow</h6>
                                <p className=" f-500 dark-gray small-title">We fund small, medium, and large businesses by purchasing your high quality receivables and collect directly from your customers. By selling credit-worthy invoices to us, your businesses receive immediate operating capital.</p>
                             </ll>
                          </ul>
                        </div>
                      </div>
                   </div>
                </section>
                <section id="HowWeWork" className="padd110 relative HowWeWork blue-b CoverBanner" style={{backgroundImage:"url('/images/about_work.png')"}}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12" data-aos='fade-right'>
                           <h2 className="f-800 white-c">How it works</h2>
                        </div>
                      </div>
                    </div>
                    <div className="HowWeWorkList mar-t-50">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6 pl_" data-aos='fade-right'> 
                              <p className="white-c mar-b-60"><span className="number_span">1</span>First, find the type of funding you need. Your rich granddad has different types of funding available:<span className="pu_"></span></p>
                              <div className="plo_m">
                                <p className="very-small-title white-c mar-b-20">If you have assets or properties, put them to work! We can provide you with equity up to the amount of your asset’s/property’s value. </p>
                                <p className="very-small-title white-c mar-b-20">To help get your business moving and keep that cash flow going, we offer accounts receivable financing, supply chain financing, equipment financing, merchant cash advance, purchase order financing, revenue loans and term loans.</p>
                                <p className="very-small-title white-c mar-b-20">For small business owners, merchant cash advance is also available, so you can run your business around a steady cash flow with an easy payment based on your actual income. </p>
                                <p className="very-small-title white-c">If you’re not really in for a large cash advance or loan, but just want to make smart investments and get a higher return than letting your money sit in the bank, we have something for you, too!</p>
                              </div>
                              <p className="white-c mar-b-60"><span className="number_span">2</span>Second, once you find the right type of funding, let your rich granddad know what you need by filling in our online application.</p>
                              <p className="white-c"><span className="number_span">3</span>Third, one of our friendly staff will put it through our system for review, and will get back to you with a pre-approval. Your actual funds can be available as early as 3 days after the final approval!</p>
                          </div>
                          <div className="col-md-2"> 
                          </div>
                          <div className="col-md-4" data-aos='fade-left'> 
                            <div className="plo">
                              <p className="very-small-title white-c mar-b-20">If you have assets or properties, put them to work! We can provide you with equity up to the amount of your asset’s/property’s value. </p>
                              <p className="very-small-title white-c mar-b-20">To help get your business moving and keep that cash flow going, we offer accounts receivable financing, supply chain financing, equipment financing, merchant cash advance, purchase order financing, revenue loans and term loans.</p>
                              <p className="very-small-title white-c mar-b-20">For small business owners, merchant cash advance is also available, so you can run your business around a steady cash flow with an easy payment based on your actual income. </p>
                              <p className="very-small-title white-c">If you’re not really in for a large cash advance or loan, but just want to make smart investments and get a higher return than letting your money sit in the bank, we have something for you, too!</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </section>
                <Partners />
            </main>
        </Layout>
    )
}
