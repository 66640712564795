import React, { Component } from 'react';
import Layout from '../components/layout'
import Partners from '../components/partners'
import Collapsible from 'react-collapsible';
import {Helmet} from "react-helmet";

export default function FAQ() {
    function handleClick(e) {
      let index = e.target.getAttribute("data-index");
      
      var elems = document.querySelectorAll(".tab-active-nav");
      [].forEach.call(elems, function(el) {
          el.classList.remove("tab-active-nav");
      });
      e.target.classList.add('tab-active-nav');

      var elems = document.querySelectorAll(".tab-active-contain");
      [].forEach.call(elems, function(el) {
          el.classList.remove("tab-active-contain");
      });

      document.querySelectorAll(".tabs-contain > li")[index].classList.add('tab-active-contain');
    }
    function handleClickMobile(e) {
      let index = e.target.getAttribute("data-index");
    }
    return (
        <Layout>
            <Helmet>
              <title>FAQ | Rich Granddad</title>
            </Helmet>
            <main>
               <section id="FAQ" className="padd40 relative no-padd-bottom faq">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <h2 className="f-800 rustin-black mar-b-30" data-aos="fade-up">Frequently Asked Questions</h2>
                      </div>
                    </div>
                  </div>
                  <div className="faq_list soft-blue-b" data-aos="fade-up">
                    <div className="vertical-tabs">
                       <div className="container">
                         <div className="row">
                            <div className="col-md-12">
                              <ul className="tabs-link">
                                <li className="tab-active-nav" data-index={0} onClick={handleClick}>Lend on Land</li>
                                <li data-index={1} onClick={handleClick}>Hard Money Mobile</li>
                                <li data-index={2} onClick={handleClick}>Solid Oak Investment</li>
                                <li data-index={3} onClick={handleClick}>Empire Factors</li>
                                <li data-index={4} onClick={handleClick}>Dan Advance</li>
                              </ul>
                              <ul className="tabs-contain">
                                <li className="tab-active-contain">
                                  <div className="tab_title_mobile"  onClick={handleClick}  data-index={0}>Lend on Land</div>
                                  <Collapsible trigger="What is a Lend on Land loan?">
                                    <p>A Lend on Land loan is a loan with usually a real estate property (in this case, a plot of land) as a collateral. They are also known as private money loans, bridge loans, short term loans, transitional loans, or asset-based loans.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Where does the funds for a Lend on Land loan come from?">
                                    <p>Private investors seeking a return on their capital would offer hard money loans. They can be any one individual or a group of investors with ready-to-use pool of funds managed by an asset manager or loan broker.</p>
                                  </Collapsible>
                                  <Collapsible trigger="When is a Lend on Land loan a better alternative than a bank loan?">
                                    <p>When a borrower needs funds fast, such as when competing with other parties in obtaining certain properties, or when they are unable to fulfill certain requirements to qualify for traditional bank loans, hard money loans could just be temporary loans until a time where they can obtain a loan from a bank and use it to pay off any existing hard money loans.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Why i should not get a traditional bank loan?">
                                    <p>Typical bank loans require a strong collateral as well as a high credit score and cash flow. Banks also can’t give out loans in as fast as fast a manner as a Lend on Land loan. Lend on Land lenders are the opposite, in that we are flexible with requirements and can provide a loan in no time.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Should a Lend on Land loan be treated as a last resort?">
                                    <p>On the contrary, they could be extremely preferable considering that many real estate deals cannot conform with conventional requirements.</p>
                                  </Collapsible>
                                  <Collapsible trigger="What happens if I can’t pay back my Lend on Land loan?">
                                    <p>Typically, the property put up as collateral would be subject to a foreclosure. However, most lenders do not desire to take that route which would cut off our relationship. They would rather work with you and do what it takes to ensure you keep your property and the relationship they’ve built thus far. The Notice of Default to trigger the legal foreclosure process is usually a last resort.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How easy is it to get approved by Lend on Land?">
                                    <p>Quick and easy. Simply fill the quick apply form and a representative will get back to you very shortly to go over the details. It usually takes less than 24 hours to get approved for your loan.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How do I qualify for a loan?">
                                    <p>You qualify if you have commercial property to put up as collateral.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How do I get started?">
                                    <p>Simply fill up the quick apply form.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How does the program work?">
                                    <p>We take your existing property as a collateral to give you the maximum amount of money for you to improve or expand your business.
</p>
                                  </Collapsible>
                                  <Collapsible trigger="How complicated is the paperwork?">
                                    <p>It’s very simple.  This is basically a cash advance with collateral as second positions in property instead of a confession of judgment.</p>
                                  </Collapsible>
                                  <Collapsible trigger="I am already with another financing company providing similar financing. Can I still get approved by Lend on Land?">
                                    <p>Yes you can get funded, even with a first mortgage position on your property.</p>
                                  </Collapsible>
                                </li>
                                <li>
                                 <div className="tab_title_mobile"  onClick={handleClick}  data-index={1}>Hard Money Mobile</div>
                                  <Collapsible trigger="What is a hard money loan?">
                                    <p>A hard money loan is a loan given based on the the asset given as collateral, usually a real estate property. They are also known by other names, such as private money loans, bridge loans, short-term loans, transitional loans, or asset-based loans</p>
                                  </Collapsible>
                                  <Collapsible trigger="Where does the funds for a hard money loan come from? ">
                                    <p>Many private investors who seek a return on their capital would make hard money loans. They can either be one individual, a group of investors who each invest an amount to your loan, or a group of private investors with a ready-to-use pool of funds managed by an asset manager or loan broker.</p>
                                  </Collapsible>
                                  <Collapsible trigger="When is a hard money loan a better alternative than a bank loan? ">
                                    <p>Sometimes a borrower needs funds fast, such as when the borrower is competing with other parties in obtaining a certain properties. Other times, they are unable to fulfil certain requirements to qualify for a traditional bank loan. Either way, a borrower needs to keep in mind that hard money loans come at a higher cost, namely a higher interest, and a shorter lending period. Therefore, many borrowers use hard money loans mainly as a bridge loan - or a temporary loan until such time when they are able to obtain loans from traditional banks, and with it pay off their hard money loans.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Why i should not get a traditional bank loan?">
                                    <p>You can always try to get a traditional bank loan. However, in general, traditional bank loans require a strong collateral as well as excellent credit score and cash flow. Furthermore, in cases where you need funds fast, banks are generally unable to make loan decisions and provide funds in a speedy manner. Hard money lenders are the exact opposite. They are flexible in their requirements, focus on the collateral for the loan, and are able to provide a loan quickly.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Aren’t hard money lenders considered “loan sharks”? ">
                                    <p>Unfortunately this negative stigma linked to hard money lenders is a result of a few bad apples in the industry. Over time, the business has evolved and many good lenders are available, serving real estate investors and closing good deals. If you’re in the market for a hard money loan, you need to know the right questions to ask potential lenders to be sure you are dealing with credible lender who can give you what you need. </p>
                                  </Collapsible>
                                  <Collapsible trigger="Should hard money loans be treated as a last resort?">
                                    <p>On the contrary, many real estate transactions can not conform with the conventional requirements, and vice versa. Therefore, hard money loans could be the preferable, if not the only, option for a borrower.   </p>
                                  </Collapsible>
                                  <Collapsible trigger="What happens if I can’t pay back the hard money loan?">
                                    <p>If you can’t pay back the loan, the property you have put up as collateral is subject to a foreclosure. However, most hard money lenders have no desire to take your property. Their service to you is an income stream for them, and by taking your property, they will lose the possible income of certain percentage points of the loan amount per year. It is in their as well as your best interest to keep you in the property. They will typically follow certain steps to try and avoid foreclosure and try to work things out amicably. The Notice of Default to trigger the legal foreclosure process is usually a last resort.</p>
                                  </Collapsible>
                                </li>
                                <li>
                                  <div className="tab_title_mobile"  onClick={handleClick}  data-index={2}>Solid Oak Investment</div>
                                  <h6 className="faq_sub">General Questions</h6>
                                  <Collapsible trigger="What is crowd investment?">
                                    <p>Crowd investment is a practice of raising small amounts of money from a large number of people as a form of investment to fund real estate projects, provide cash advance for small business capital, purchase tax liens certificates on properties, and other projects.   </p>
                                  </Collapsible>
                                  <Collapsible trigger="Is crowd investment safe?">
                                    <p>Yes. The platform we use is very secure as it uses 128 bit encryption, the very same encryption that is used by major banks the world over. It also sets a time limit of 15 minutes to inactivity so that your membership cannot be accessed without your knowledge if you forget to log out.
Solid Oak endeavours to create a safe, supportive and secure member community. We vet and approve all of our investors before membership access is granted and every investment opportunity before it is offered. 
Additionally, our platform is based on the highly successful 'all-or-nothing' crowdfunding model. This means that the funds you have invested will only be used when the deal you chose to invest in has reached its targeted amount. 
 </p>
                                  </Collapsible>
                                  <Collapsible trigger="Is real estate crowd investment legal?">
                                    <p>Yes. While property crowd investment may be a new concept, we've made sure that it is compliant with the appropriate state, territory and federal laws. </p>
                                  </Collapsible>
                                  <Collapsible trigger="How does it work?">
                                    <p>Solid Oak curates various investment opportunities in real estate, business capital, and tax liens to be presented on this platform. Before these deals are offered, we conduct thorough risk assessments or due diligence by evaluating each property based on multiple criteria to ensure that the deals are the best investment opportunities with highest yield and lowest risk. Once offered, investors are able to select the deals they are interested to invest in by signing up on our platform. and once it is completed, some legal documents are signed and the investor submits their funds, after which their investment will be final. These will be held securely at a highly rated financial institution until the chosen deal’s investment goal is met, and the transaction is completed. The total funds are then channeled into the deal, to be used for its specific purpose. If it’s used as a hard money loan for a real estate development, the lender will make their monthly payment with interest, which will be the investor’s return. In case of cash advances for small business capital, the investor’s return per month will be a percentage of the lender’s debit/credit card sales. The return from tax liens investments come from the property owner’s repayment of delinquent tax amount, with interest. These returns are transferred to the investor’s account periodically, and at the end of the investment period when the deal is paid off, the investor has the choice of withdrawing the initial investment or reinvesting it into other deals.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Who can invest?">
                                    <p>Anyone who is accredited can invest in any deals offered. </p>
                                  </Collapsible>
                                  <Collapsible trigger="What is the minimum investment?">
                                    <p>We strive to make millionaire-type investment opportunities accessible to all, therefore we have set an affordable level of entry of $1,000 with the ability to invest more, in the multiples of $1,000. </p>
                                  </Collapsible>
                                  <Collapsible trigger="What happens after I invest? Where do my funds go?">
                                    <p>Your investment is considered final once legal documents are signed and your fund is submitted. However, your funds will only be channeled into the deal of your choice once the investment goal is met and the transaction is completed. Once both prerequisites are fulfilled, your fund will be channeled into the deal of your choice. In a real estate development, your money will be used for the real estate development plan; in a small business capital investment, your money will be used as the lender’s capital to start their business, and in tax liens investments, your fund is used to pay off a property’s delinquent tax. </p>
                                  </Collapsible>
                                  <Collapsible trigger="What do I receive once I invest?">
                                    <p>Once you invest, you will get a book entry into an entity that protects your interest.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How much is the return of investment?">
                                    <p>It will depend on what deal you invested in, ranging from 7% to 20%.</p>
                                  </Collapsible>
                                  <Collapsible trigger="What are the fees?">
                                    <p>There are no fees. All returns presented in each deal are net.</p>
                                  </Collapsible>
                                  <Collapsible trigger="When will I get my return?">
                                    <p>The timing varies between monthly, semi-annually, annually, or biannually, depending on what deal you have invested in.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Can I invest in more than one product and/or offering?">
                                    <p>Yes, you can, as many as you are able to fund. In fact, we encourage diversifying your investment, to spread out the risks, and somewhat ensure that the overall performance of your portfolio will deliver an acceptable rate of return for the assumed risks.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How do I monitor the use and development of my funds?">
                                    <p>This platform provides tools which allow investors to monitor and manage your funds and investments efficiently. Real time updates of their investments will be shown when they are logged in on the platform, on their investor’s dashboard. We will also update you whenever we distribute funds to your accounts.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Can I lose any of my investments?">
                                    <p>There is no such thing as a guaranteed investment, and there will always be a risk of loss with any investments. However, one benefit of investing through Solid Oak is that every deal you invest in is secured by a collateral. In the event that a lender is unable to repay their loans or tax, we will take measures to recover the outstanding amounts, minimising the loss, if any.  </p>
                                  </Collapsible>
                                  <Collapsible trigger="What protects my investment from loss?">
                                    <p>Every deal you invest in is secured by collateral, in the form of a real estate property. If a lender is unable to repay their loans or tax, this property will be foreclosed in the effort of recovering the balance, and therefore minimising, if not eliminating, any loss of investor’s funds.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Can I invest more after my initial investment?">
                                    <p>Yes, we welcome any further investments. You simply need to log in to your account and select any of the deal offers you are interested in.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Can I withdraw my funds at any time?">
                                    <p>Unfortunately you can not. Once your fund has been channeled to the deal you have chosen, and transactions have been made, you will not be able to withdraw your funds. This is to protect the interest of all parties involved. Therefore, before making any commitments to a deal, be sure that you only invest in an amount you can afford, and the deal you are investing in is in fact something that you find promising. </p>
                                  </Collapsible>
                                  <h6 className="faq_sub">Private market real estate investments</h6>
                                  <Collapsible trigger="What am I investing in?">
                                    <p>You are taking part in hard money/asset based loans provided to real estate investors which will be used in various commercial real estate projects.  </p>
                                  </Collapsible>
                                  <Collapsible trigger="How much is the return of investment?">
                                    <p>In real estate investment, the return can be anywhere between 9% and 14% annually.</p>
                                  </Collapsible>
                                  <Collapsible trigger="When will I get my return?">
                                    <p>We will transfer your return to your account every month.</p>
                                  </Collapsible>
                                  <h6 className="faq_sub">Cash advance / small business capital</h6>
                                  <Collapsible trigger="What am I investing in?">
                                    <p>You are basically giving a loan to small business owners to start up their businesses in exchange for an agreed-upon percentage of their future credit card and/or debit card sales. </p>
                                  </Collapsible>
                                  <Collapsible trigger="How much is the return of investment?">
                                    <p>The return ranges between 15% and 20%. </p>
                                  </Collapsible>
                                  <Collapsible trigger="When will I get my return?">
                                    <p>Returns from cash advance investments can be paid out either annually or biannually, depending on each deal. </p>
                                  </Collapsible>
                                  <Collapsible trigger="What am I investing in?">
                                    <p>You are participating in the purchase of property tax liens from the issuing municipality. The issuing municipality then collects the principal and interest from the property owner to pay back the loan to the investor.   </p>
                                  </Collapsible>
                                  <Collapsible trigger="How much is the return of investment?">
                                    <p>The return is between 7% and 15%, depending on whether or not and how many properties were foreclosed.</p>
                                  </Collapsible>
                                  <Collapsible trigger="When will I get my return?">
                                    <p>We transfer returns from tax liens investments semi-annually (twice a year). 
</p>
                                  </Collapsible>
                                </li>
                                <li>
                                <div className="tab_title_mobile"  onClick={handleClick}  data-index={3}>Empire Factors</div>
                                  <Collapsible trigger="What is factoring?">
                                    <p>Factoring is the sale of credit-worthy invoices to us. In return, businesses receive immediate operating capital and, in exchange, we receive fees for funding the transaction.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How big is the factoring industry?">
                                    <p>Factoring has been practiced for thousands of years. It is used on every continent of the globe and worldwide volumes exceed $3 trillion annually.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How does factoring work?">
                                    <p>We buy your invoices for goods or services sold and delivered to your customers. You receive immediate cash in advance rather than wait on your customer to pay. When your customer pays us, we will deduct the initial advance you received and our fees, and pay the balance to you. </p>
                                  </Collapsible>
                                  <Collapsible trigger="Does my billing to my customers continue?">
                                    <p>Yes. However, you direct payment to us on your behalf, so we collect. </p>
                                  </Collapsible>
                                  <Collapsible trigger="How does a customer know to direct payment to Empire Factors?">
                                    <p>Your customer will be notified by us to send the payment to our bank lockbox. </p>
                                  </Collapsible>
                                  <Collapsible trigger="Do you contact my customers?">
                                    <p>Yes, the customers whose invoices you factor will be contacted.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Am I required to factor all my customer’s invoices?">
                                    <p>No, you decide which customer’s invoice to factor while we decide on which to fund. </p>
                                  </Collapsible>
                                  <Collapsible trigger="Do my customers have to know I am factoring?">
                                    <p>Yes, we notify your customers, so they will know. This is not unusual and most companies are very familiar with it.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Is there a required minimum or maximum factoring volume?">
                                    <p>No, but more volume can lead to lower fees.</p>
                                  </Collapsible>
                                  <Collapsible trigger="What is the cost of your factoring services?">
                                    <p>Depends upon industry, volume, payment terms to determine pricing, but generally between 2% and 5% each thirty days. </p>
                                  </Collapsible>
                                  <Collapsible trigger="When you fund what is the advance amount?">
                                    <p>Advances are 80% to 95% of the face amount of an invoice. We set the advance at the beginning depending upon certain risk factors to be determined during the underwriting process.</p>
                                  </Collapsible>
                                  <Collapsible trigger="When do advances get paid?">
                                    <p>Each time you submit invoices for funding. Advances are paid by wire transfer or ACH into your account. </p>
                                  </Collapsible>
                                  <Collapsible trigger="When do you release the balance of an invoice?">
                                    <p>After collection all balances less applicable fees will be paid to you.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Are long term contracts required?">
                                    <p>We do not require long term contracts. </p>
                                  </Collapsible>
                                  <Collapsible trigger="How do I qualify for your services?">
                                    <p>If you are a business selling goods or services on terms to other credit-worthy and reputable businesses, you will most likely qualify.</p>
                                  </Collapsible>
                                  <Collapsible trigger="What if I have some credit issues, a prior bankruptcy, existing loan or line of credit?">
                                    <p>With factoring the decision to fund is largely based on the credit-worthiness of your customers who are paying us. Part of what we do is help you clean up your credit issues. </p>
                                  </Collapsible>
                                  <Collapsible trigger="Do you work with small businesses and start-ups?">
                                    <p>Yes, that’s the target market.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How long will it take to get funded?">
                                    <p>Setting up an account is 5 to 10 days and funding within 24-48 hours.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How can I get started?">
                                    <p>Please submit an application along with support documents. </p>
                                  </Collapsible>
                                  <Collapsible trigger="Are there any upfront fees?">
                                    <p>We do not charge fees to get started.</p>
                                  </Collapsible>
                                  <Collapsible trigger="What supporting documents do you require? ">
                                    <p>Our checklist includes information regarding business registration, principals, customer list, invoices, and copy of accounts receivable aging.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Can you factor anywhere in the USA in any industry?">
                                    <p>Yes, and we will look at any industry except construction.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Who keeps track of invoices and payments?">
                                    <p>Our in-house staff does. You will have access to our online portal that will provide regular reports.</p>
                                  </Collapsible>
                                  <Collapsible trigger="What if a customer doesn’t pay an invoice?">
                                    <p>We require a guarantee that you will pay unpaid invoices.  </p>
                                  </Collapsible>
                                  <Collapsible trigger="Do I have to sign a personal guarantee?">
                                    <p>Yes, anyone owning more than 20% of the business must sign a guarantee.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Can I stop factoring?">
                                    <p>Yes, simply send notice of termination and once accounts are paid in full and obligations are satisfied, any remaining reserves or future receipts will be paid to you, and release will be completed.</p>
                                  </Collapsible>
                                </li>
                                <li>
                                <div className="tab_title_mobile" onClick={handleClick}  data-index={4}>Dan Advance</div>
                                  <Collapsible trigger="How do I qualify? You qualify if…">
                                    <p>
                                      You own a business that already accepts credit cards (VISA, MASTERCARD, AMEX, DINER’S CLUB) as a form of payment.
                                      Your business processes a minimum of $1,500 dollars a month in credit card sales.
                                      You can provide recent credit card statements and bank statements (3 months if business is not seasonal and 12 months if business is seasonal).
                                      You have been in business at least 1 year.
                                    </p>
                                  </Collapsible>
                                  <Collapsible trigger="How easy is it to get approved by Dan Advance Funding?">
                                    <p>
                                    It is a lot easier and faster than a typical bank loan.
We understand the reality and the hardship of owning and operating a small to medium size business. The majority of our Funding Specialists have also owned and operated their own small to medium size businesses and understand how quickly you need capital, so our efforts to get you business funding is a top priority. Just as long as you own a business for at least 1 year and already accept credit card payments, you have made the process that much easier!
                                    </p>
                                  </Collapsible>
                                  <Collapsible trigger="How can I speed up my cash advance approval?">
                                    <p>Please make sure to immediately submit any information you might have omitted from the 1-page application and quickly email your Visa/MasterCard processing statements and bank statements to your Funding Specialist. Also, provide all documentation explaining any items that may create a questionable credit report and email it to info@danadvance.com.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How do I get started?">
                                    <p>Call Toll-Free today at 1-877-227-4060 and a Funding Specialist will walk you through the application process and help to answer all your questions.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How does the program work?">
                                    <p>You can be advanced as little as $1,500 up to $1,000,000 based on your current credit card sales receipts.
A small agreed upon percentage is then deducted from your ongoing/future Visa and MasterCard receipts for payback.
There are no fixed payments and there is no fixed term time frame.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How much will unsecured cash advance cost?">
                                    <p>Pricing is based on the size of the cash advance, the estimated time of distribution, and various other integral details of the cash advance program.
This is explained at length in the Cash Advance Approval sheet</p>
                                  </Collapsible>
                                  <Collapsible trigger="Will bad credit affect my unsecured cash advance request?">
                                    <p>It is not necessary for you to have good credit in order to receive an unsecured cash advance.
A poor credit rating, including delinquencies, will not normally influence an unsecured cash advance.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How fast can I get an unsecured cash advance?">
                                    <p>In as fast as 72 hours! However, if relevant documents are missing or are difficult to obtain, the process can take as long as two weeks or more</p>
                                  </Collapsible>
                                  <Collapsible trigger="How much of an unsecured cash advance can I receive?">
                                    <p>$1,500 up to $1,000,000</p>
                                  </Collapsible>
                                  <Collapsible trigger="How will I pay back the unsecured cash advance?">
                                    <p>On a formula calculated based on your cash flow projections.
We partner with you to determine your optimal payment</p>
                                  </Collapsible>
                                  <Collapsible trigger="Is this a loan?">
                                    <p>No. This is not a loan.
It is a purchase of future credit card receivables, similar to a cash advance. However, it works in much the same way as a business loan would, only there is no fixed repayment schedule.</p>
                                  </Collapsible>
                                  <Collapsible trigger="When I have repaid my first cash advance, how easy is it to apply again?">
                                    <p>If you have experienced no change in financial status you will be approved automatically.</p>
                                  </Collapsible>
                                  <Collapsible trigger="How complicated is the paperwork?">
                                    <p>The paperwork that you will need to fill out is quite simple and takes no more than ten or fifteen minutes to complete.
We have Funding Specialists on hand in our call center to help you through the entire process</p>
                                  </Collapsible>
                                  <Collapsible trigger="I am already with another financing company providing similar financing. Does that mean I will get approved by Dan Advance Funding?">
                                    <p>Our credit underwriting standard is slightly higher than that of similar companies; however, approximately 95% of our competitors’ customers who applied for our funding have been accepted.
The only sure way to find out is by submitting an application.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Can I receive financing from Dan Advance while the financing from another company is still outstanding?">
                                    <p>Yes, but we must pay off any outstanding cash advances out of our first advance to you. (You receive all remaining proceeds).
Depending on the particular finance company involved, there may be an exception to this policy, but most other credit card receivable companies will have to be paid off out of the first advance.</p>
                                  </Collapsible>
                                  <Collapsible trigger="I have high enough overall sales, but my VISA/MC volume is very low. Is there any way I could receive funding?">
                                    <p>Yes. The minimum requirement for qualification of the unsecured cash advance program is $1,500 of your monthly VISA/MC transactions. We custom tailor our products to suit our customers’ needs all the time. Just call and ask!</p>
                                  </Collapsible>
                                  <Collapsible trigger="Can the unsecured cash advance be renewed?">
                                    <p>When the advance balance is down to approximately 20% of the original balance, the borrower can request for more money. As a matter of fact, most of our customers opt to do just that. The owner of the business can request a renewal by phone, fax, or e-mail. Renewals are usually funded in less than 24 hours of being requested.</p>
                                  </Collapsible>
                                  <Collapsible trigger="Do I have to switch my current credit card processor?">
                                    <p>Our affiliated credit card processing companies will meet or beat your current credit card processing cost structure. The bottom line is that you’re not going to lose money for having to switch. (Most likely, you will be saving money by switching)</p>
                                  </Collapsible>
                                  <Collapsible trigger="Do I have to get a new credit card terminal?">
                                    <p>Not usually. All you have to do is re-program your terminal, which takes only 10 minutes and it can be done over the phone. Our credit card processor will call you to give step-by-step instructions.</p>
                                  </Collapsible>
                                  <Collapsible trigger="What if I am not happy with Dan Advance Funding’s affiliated credit card processor?">
                                    <p>We will try to resolve any issues right away for you. If not, we will switch you to one of our other processors. But staying with one of our processors is mandatory</p>
                                  </Collapsible>
                                </li>
                              </ul>
                            </div>
                         </div>
                       </div>
                    </div>
                  </div>
              </section>
              <Partners />
            </main>
        </Layout>
    )
}
