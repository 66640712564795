import React, { Component } from 'react';
import { TextField} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import axios from 'axios';

export default function DanAdvance() {
    const { register, handleSubmit, errors,reset  } = useForm();
    const [succes, setSucces] = React.useState();

    const onSubmit = (data, e) => {
      let formData = new FormData()

      formData.set("owner_name", data.owner_name)
      formData.set("email_address", data.email_address)
      formData.set("phone_number", data.phone_number)
      formData.set("company_name", data.company_name)
      formData.set("website", data.website)
      formData.set("advance_amount", data.advance_amount)
      formData.set("advance_balance", data.advance_balance)
      formData.set("advance_provider", data.advance_provider)
      formData.set("platform", 'Rich Grand Dad')
      

      axios.post('https://app.danadvance.com/api/webdeal', formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
       .then((response) => {
         e.target.reset();
         setSucces('Thank you for contacting us - we will get back to you soon!');
       }, (error) => {
         console.log(error);
       });
    };
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
        <div className="col-md-6">
           <TextField id="standard-basic" label="Owner Name" name="owner_name"  inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.owner_name && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Email" name="email_address" inputRef={register({
                     required: "This field is required",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                     }
                  })}/>
            <span className="error_field">{errors.email_address && errors.email_address.message}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Phone" name="phone_number"  inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.phone_number && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Company  Name" name="company_name"  inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.company_name && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Website" name="website"  inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.website && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Advance Amount" name="advance_amount"  inputRef={register({ required: "This is required" })} type="number"/>
           <span className="error_field">{errors.advance_amount && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Advance Balance" name="advance_balance"  inputRef={register({ required: "This is required" })} type="number"/>
           <span className="error_field">{errors.advance_balance && "This field is required"}</span>
        </div>
        <div className="col-md-6">
           <TextField id="standard-basic" label="Advance Provider" name="advance_provider"  inputRef={register({ required: "This is required" })}/>
           <span className="error_field">{errors.advance_provider && "This field is required"}</span>
        </div>
        
        <div className="col-md-12">
           <span className="succes_field">{succes}</span>
           <button className="btn_blue mar-t-30"><i className="icon icon-rocket"></i>Send</button>
        </div>
      </div>
      </form>
        )
}