import React, { Component } from 'react';
import Layout from '../components/layout'
import Partners from '../components/partners'

import LendonLand from '../components/form/lend_on_land';
import HardMoneyMobile from '../components/form/hard_money_mobile';
import SolidOakInvestment from '../components/form/solid_oak_investment';
import DanAdvance from '../components/form/danadvance';
import Quantak from '../components/form/quantak';
import EmpireFactors from '../components/form/empire_factors';
import CenturyCapitalPartners from '../components/form/century_capital_partners';
import {Helmet} from "react-helmet";
import AOS from 'aos';
export default function FAQ() {

    function handleClick(e) {
      let index = e.target.getAttribute("data-index");
      
      var elems = document.querySelectorAll(".tab-active-nav");
      [].forEach.call(elems, function(el) {
          el.classList.remove("tab-active-nav");
      });
      e.target.classList.add('tab-active-nav');

      var elems = document.querySelectorAll(".tab-active-contain");
      [].forEach.call(elems, function(el) {
          el.classList.remove("tab-active-contain");
      });

      document.querySelectorAll(".tabs-contain > li")[index].classList.add('tab-active-contain');

      AOS.refresh()
    }
    return (
        <Layout>
            <Helmet>
              <title>Apply Now | Rich Granddad</title>
            </Helmet>
            <main>
               <section id="FAQ" className="padd40 relative no-padd-bottom faq">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <h2 className="f-800 rustin-black mar-b-30" data-aos="fade-up">Apply Now</h2>
                      </div>
                    </div>
                  </div>
                  <div className="faq_list soft-blue-b" data-aos="fade-up">
                    <div className="vertical-tabs">
                       <div className="container">
                         <div className="row">
                            <div className="col-md-12">
                              <ul className="tabs-link">
                                <li className="tab-active-nav" data-index={0} onClick={handleClick}>Lend on Land</li>
                                <li data-index={1} onClick={handleClick}>Hard Money Mobile</li>
                                <li data-index={2} onClick={handleClick}>Solid Oak Investment</li>
                                <li data-index={3} onClick={handleClick}>Empire Factors</li>
                                <li data-index={4} onClick={handleClick}>Dan Advance</li>
                                <li data-index={6} onClick={handleClick}>Century Capital Partners</li>
                                <li data-index={5} onClick={handleClick}>Quantak</li>
                              </ul>
                              <ul className="tabs-contain">
                                <li className="tab-active-contain">
                                  <div className="tab_title_mobile"  onClick={handleClick}  data-index={0}>Lend on Land</div>
                                  <LendonLand />
                                </li>
                                <li>
                                  <div className="tab_title_mobile"  onClick={handleClick}  data-index={1}>Hard Money Mobile</div>
                                  <HardMoneyMobile />
                                </li>
                                <li>
                                  <div className="tab_title_mobile"  onClick={handleClick}  data-index={2}>Solid Oak Investment</div>
                                  <SolidOakInvestment />
                                </li>
                                <li>
                                  <div className="tab_title_mobile"  onClick={handleClick}  data-index={3}>Empire Factors</div>
                                  <EmpireFactors />
                                </li>
                                <li>
                                  <div className="tab_title_mobile"  onClick={handleClick}  data-index={4}>Dan Advance</div>
                                  <DanAdvance />
                                </li>
                                <li>
                                  <div className="tab_title_mobile"  onClick={handleClick}  data-index={5}>Quantak</div>
                                  <Quantak />
                                </li>
                                <li>
                                  <div className="tab_title_mobile"  onClick={handleClick}  data-index={6}>Century Capital Partners</div>
                                  <CenturyCapitalPartners />
                                </li>
                              </ul>
                            </div>
                         </div>
                       </div>
                    </div>
                  </div>
              </section>
              <Partners />
            </main>
        </Layout>
    )
}
